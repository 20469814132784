import React, { useContext } from "react";
import { ThemeContext } from "../context/themeContext";
import SubHeader from "../components/SubHeader";
import Tab from "../components/TabContainer";

const PresetModal = ({
  closeModal,
  filterSubHeader,
  filterMenuDetails,
  renderDropdownContent,
  presetNames,
  setIsOpen,
}) => {
  const { theme } = useContext(ThemeContext);
  const handleSave = () => {
    closeModal();
  };

  return (
    <div className="bg-background p-12 scrollHide rounded-lg w-[360px] sm:w-[600px] md:w-[700px] lg:w-[800px] relative overflow-auto h-[700px] md:h-auto">
      <div className="bg-background mb-4 flex justify-between items-center">
        <div>
          <SubHeader>
            <p className="title text-primary text-3xl font-medium">
              Presets
              <span className="text-textTitle ml-1"> bearbeiten</span>
            </p>
            <p className="subtitle2 mt-2">{filterSubHeader}</p>
          </SubHeader>
        </div>
        <div
          onClick={() => {
            closeModal();
            setIsOpen(true);
          }}
          className="bg-secondary w-[30px] h-[30px] flex justify-center items-center cursor-pointer rounded absolute top-4 right-14"
        >
          <img
            src={
              theme === "light"
                ? "static/LightMode/PresetArrow-Light.svg"
                : "static/DarkMode/PresetArrow-Dark.svg"
            }
            width={20}
            className="float-right"
            alt="close"
          />
        </div>
        <div
          onClick={closeModal}
          className="bg-secondary w-[30px] h-[30px] flex justify-center items-center cursor-pointer rounded absolute top-4 right-3"
        >
          <img
            src={
              theme === "dark"
                ? "static/close-1.svg"
                : "static/LightMode/close-2.svg"
            }
            width={15}
            className="float-right"
            alt="close"
          />
        </div>
      </div>
      <div className="flex justify-center w-full">
        <Tab
          renderDropdownContent={renderDropdownContent}
          filterMenuDetails={filterMenuDetails}
          presetNames={presetNames}
        />
      </div>
      <div className="flex justify-center w-[200px] ml-[-20px] sm:ml-[90px] md:ml-[-15px] lg:ml-[35px] mt-4 ">
        <button
          className="p_detail text-xs font-semibold p-3 text-text bg-gradient-to-br from-primary to-main rounded text-center w-32 border-none capitalize mt-4"
          onClick={handleSave}
        >
          speichern
        </button>
      </div>
    </div>
  );
};

export default PresetModal;
