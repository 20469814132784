import React, { useContext, useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { fetchProductDetails } from "../redux/slices/productDetailsSlice";
import { useCheckUserAuth } from "../utlis/useCheckUserAuth";
import { ThemeContext } from "../context/themeContext";
import Skeleton from "react-loading-skeleton";

const ProductRightCard = ({ selectedProduct }) => {
  const { theme } = useContext(ThemeContext);
  const imgRef = useRef(null);

  const dispatch = useDispatch();
  const selectedProductDetails = useSelector(
    (state) => state.productDetails.productDetails
  );
  const authStatus = useSelector((state) => state.productDetails.authStatus);
  useCheckUserAuth(authStatus);

  const [selectedProductData, setSelectedProductData] = useState({});
  const [storeSearchData, setStoreSearchData] = useState([]);
  const [storeSalesData, setStoreSalesData] = useState([]);
  const [informationData, setInformationData] = useState([]);
  const [topbarData, setTopbarData] = useState([]);
  const [isChartLoader, setIsChartLoader] = useState(false);
  const [chartImage, setChartImage] = useState("");
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    if (Object.keys(selectedProduct).length > 0) {
      const asin = selectedProduct.asin;
      dispatch(fetchProductDetails(asin));
      setLoading(true);
    }
  }, [selectedProduct]);

  useEffect(() => {
    if (selectedProductDetails && selectedProductDetails.data) {
      setSelectedProductData(selectedProductDetails.data);
      setTimeout(() => {
        setLoading(false);
      }, 1500);
    }
  }, [selectedProductDetails]);

  const convertToDomain = (url) => {
    const domain = new URL(url).hostname;
    const mainDomain = domain.split(".").slice(-2).join(".");
    return mainDomain;
  };

  useEffect(() => {
    let storeData = [];

    if (selectedProductData.top_bar) {
      setTopbarData(selectedProductData.top_bar);
    }

    if (selectedProductData.store_search) {
      selectedProductData.store_search.forEach((store) => {
        storeData.push({
          title: convertToDomain(store.store_search),
          value: store.store_search_price,
          link: store.store_search,
        });
      });
      setStoreSearchData(storeData);
    }

    if (selectedProductData.sales_data) {
      setStoreSalesData(selectedProductData.sales_data);
    }

    if (selectedProductData.information) {
      setInformationData(selectedProductData.information);
    }

    if (selectedProductData.graph_img) {
      if (theme === "dark") {
        if (selectedProductData.graph_img.dark) {
          setChartImage(selectedProductData.graph_img.dark);
        } else {
          setChartImage("static/NotAvailableChart-Dark.png");
        }
      } else {
        if (selectedProductData.graph_img.white) {
          setChartImage(selectedProductData.graph_img.white);
        } else {
          setChartImage("static/NotAvailableChart-White.png");
        }
      }
    }
  }, [selectedProductData, theme]);

  useEffect(() => {
    if (chartImage) {
      setIsChartLoader(true);
    }
  }, [chartImage]);

  useEffect(() => {
    if (imgRef.current && imgRef.current.complete) {
      setIsChartLoader(false);
    }
  }, [imgRef.current]);

  return (
    <div
      className="flex flex-col text-[13px] md:w-auto  pb-[120px]  sm:pb-0 2xl:pb-[60px] 3xl:pb-[0px] "
      style={{
        height: "calc(100vh - 198px)",
      }}>
      <div
        className="rounded-2xl p-2 shadow-[0px_0px_4px_0px_#0000001A] overflow-auto scrollHide h-[50%] 
         dark:border-[0.5px]  border-[#353539] bg-white dark:bg-[#07070b]">
        <div className="p-2">
          <div className="text-cardTitle list-none">
            <p
              className="text-[18px] font-[400] truncate dark:text-[#E6E6E6] text-[#00000099]"
              id="title">
              {loading ? (
                <Skeleton
                  width="94.5%"
                  height={30}
                  baseColor={theme === "dark" ? "#0e0e12" : "#f9f9f9"}
                  highlightColor={theme === "dark" ? "#1a1a1a" : "#ffffff"}
                />
              ) : (
                selectedProduct.name
              )}
            </p>
          </div>
        </div>
        <div className="left-div pb-0 ">
          <div className="grid grid-cols-3 gap-5 pb-2 px-4">
            {loading || topbarData.length === 0
              ? // Render skeletons when loading or topbarData is empty
                Array.from({ length: 3 }).map((_, i) => (
                  <div key={i} className="text-left">
                    <p className="subtitle !text-[#5F5E5E] !text-[11px] ">
                      <Skeleton
                        width={189}
                        height={22}
                        baseColor={theme === "dark" ? "#0e0e12" : "#f9f9f9"}
                        highlightColor={
                          theme === "dark" ? "#1a1a1a" : "#ffffff"
                        }
                      />
                    </p>
                    <Skeleton
                      width={189}
                      height={28}
                      baseColor={theme === "dark" ? "#0e0e12" : "#f9f9f9"}
                      highlightColor={theme === "dark" ? "#1a1a1a" : "#ffffff"}
                    />
                  </div>
                ))
              : // Render actual data when loading is false and topbarData is not empty
                topbarData.map((item, i) => (
                  <div key={`${item.name}-${i}`} className="text-left">
                    <p className="subtitle !text-[#5F5E5E] !text-[11px] ">
                      {item.name}
                    </p>
                    <div className="rounded-md w-fit shadow-[0px_0px_5px_0px_#0000001A] dark:!bg-[#121215] bg-white">
                      <p
                        className="p_detail px-8 pt-1 pb-1  text-[12px] 2xl:!text-[14px]  dark:!bg-text-simple-dark bg-text-simple-light text-transparent "
                        style={{ backgroundClip: "text" }}>
                        {item.value}
                      </p>
                    </div>
                  </div>
                ))}
          </div>

          {/* <div className=" rounded-md w-fit shadow-[0px_0px_5px_0px_#0000001A] dark:!bg-[#121215] bg-white">
            <p
              className="p_detail px-8 pt-1 pb-1  text-[12px] 2xl:!text-[14px]  dark:!bg-text-simple-dark bg-text-simple-light text-transparent "
              style={{ backgroundClip: "text" }}>
              {item.value}
            </p>
          </div> */}

          <div className="px-4 pt-2">
            <div className="md:grid grid-cols-3 flex flex-wrap max-sm:grid-cols-1 max-xl:grid-cols-3 max-md:grid-cols-3 max-2xl:grid-cols-3 gap-3">
              <div>
                <div className="flex gap-2 pt-[4px] pb-[4px]">
                  {loading ? (
                    <Skeleton
                      width={189}
                      height={22}
                      baseColor={theme === "dark" ? "#0e0e12" : "#f9f9f9"}
                      highlightColor={theme === "dark" ? "#1a1a1a" : "#ffffff"}
                    />
                  ) : (
                    <>
                      <p className="!text-[#5F5E5E] text-[11px] ">
                        STORE SEARCH
                      </p>
                      <img
                        src="static/store-search.svg"
                        width={15}
                        alt="store"
                      />
                    </>
                  )}
                </div>

                <div className="mb-2">
                  <div className="text-left w-full md:flex flex-col gap-2">
                    {loading ? (
                      <>
                        <Skeleton
                          width={189}
                          height={22}
                          baseColor={theme === "dark" ? "#0e0e12" : "#f9f9f9"}
                          highlightColor={
                            theme === "dark" ? "#1a1a1a" : "#ffffff"
                          }
                        />
                        <Skeleton
                          width={189}
                          height={22}
                          baseColor={theme === "dark" ? "#0e0e12" : "#f9f9f9"}
                          highlightColor={
                            theme === "dark" ? "#1a1a1a" : "#ffffff"
                          }
                        />
                        <Skeleton
                          width={189}
                          height={22}
                          baseColor={theme === "dark" ? "#0e0e12" : "#f9f9f9"}
                          highlightColor={
                            theme === "dark" ? "#1a1a1a" : "#ffffff"
                          }
                        />
                      </>
                    ) : (
                      storeSearchData.map((item, index) => {
                        return (
                          <a
                            className=" shrink-0"
                            href={item.link}
                            target="_blank"
                            rel="noreferrer">
                            <div
                              key={`${item.title}-${index}`}
                              className="   justify-between flex   items-center gap-[15px]">
                              <div
                                className="flex px-2 h-8 min-w-8 w-full
                            shadow-[0px_0px_5px_0px_#0000001A] items-center  dark:!bg-[#121215]  bg-white rounded-md">
                                <img
                                  src={
                                    theme === "dark"
                                      ? "static/DarkMode/spy-link.svg"
                                      : "static/LightMode/spy-link.svg"
                                  }
                                  width={15}
                                  alt="link"
                                />
                                <p
                                  className="p_detail px-2 pt-1 pb-1 
                                   truncate dark:!bg-text-simple-dark bg-text-simple-light text-transparent 2xl:text-[14px] "
                                  style={{ backgroundClip: "text" }}>
                                  {item.title}
                                </p>
                              </div>

                              <div
                                className="rounded min-w-16 flex items-center justify-center shadow-[0px_0px_5px_0px_#0000001A] 
                                !bg-bg-green-gradient-light dark:!bg-bg-green-gradient-dark bg-white h-8 m-[7px]
                               dark:bg-black 2xl:!m-0 px-[10px]">
                                <p
                                  className="p_detail  rounded-md !text-[12px]  flex items-center justify-center dark:bg-text-green-dark bg-text-green-light text-transparent 2xl:!text-[14px] "
                                  style={{ backgroundClip: "text" }}>
                                  {item.value}€
                                </p>
                              </div>
                            </div>
                          </a>
                        );
                      })
                    )}
                  </div>
                </div>
              </div>

              <div>
                <div className="flex gap-2 pt-[4px] pb-[4px]">
                  {loading ? (
                    <Skeleton
                      width={189}
                      height={22}
                      baseColor={theme === "dark" ? "#0e0e12" : "#f9f9f9"}
                      highlightColor={theme === "dark" ? "#1a1a1a" : "#ffffff"}
                    />
                  ) : (
                    <>
                      <p className="!text-[#5F5E5E]  text-[11px] ">
                        PRODUCT DATA
                      </p>
                      <img
                        src="static/sales-data copy.svg"
                        width={10}
                        alt="sales"
                      />
                    </>
                  )}
                </div>

                <div className="mb-2">
                  <div className="text-left w-full md:flex flex-col gap-2">
                    {loading ? (
                      <>
                        <Skeleton
                          width={189}
                          height={22}
                          baseColor={theme === "dark" ? "#0e0e12" : "#f9f9f9"}
                          highlightColor={
                            theme === "dark" ? "#1a1a1a" : "#ffffff"
                          }
                        />
                        <Skeleton
                          width={189}
                          height={22}
                          baseColor={theme === "dark" ? "#0e0e12" : "#f9f9f9"}
                          highlightColor={
                            theme === "dark" ? "#1a1a1a" : "#ffffff"
                          }
                        />
                        <Skeleton
                          width={189}
                          height={22}
                          baseColor={theme === "dark" ? "#0e0e12" : "#f9f9f9"}
                          highlightColor={
                            theme === "dark" ? "#1a1a1a" : "#ffffff"
                          }
                        />
                      </>
                    ) : (
                      storeSalesData.map((item, i) => {
                        return (
                          <div
                            key={`${item.name}-${i}`}
                            className=" rounded  justify-between flex items-center gap-[15px]">
                            <div className="flex items-center px-2 min-w-8 w-full rounded-md h-8  shadow-[0px_0px_5px_0px_#0000001A] dark:!bg-[#121215]  bg-white">
                              <img
                                src={
                                  theme === "dark"
                                    ? "static/DarkMode/spy-round.svg"
                                    : "static/LightMode/spy-round.svg"
                                }
                                width={8}
                                alt="link"
                              />
                              <p
                                className="p_detail px-2 pt-1 pb-1  2xl:text-[14px] truncate dark:!bg-text-simple-dark bg-text-simple-light text-transparent "
                                style={{ backgroundClip: "text" }}>
                                {item.name}
                              </p>
                            </div>

                            <div className="rounded flex items-center  justify-center min-w-16 shadow-[0px_0px_5px_0px_#0000001A] px-[10px] !bg-bg-green-gradient-light dark:!bg-bg-green-gradient-dark bg-white h-8 m-[7px] dark:bg-black 2xl:!m-0">
                              <p
                                className="p_detail  rounded-md   !text-[12px]  flex items-center justify-center dark:bg-text-green-dark bg-text-green-light text-transparent 2xl:!text-[14px]"
                                style={{ backgroundClip: "text" }}>
                                {item.value}
                              </p>
                            </div>
                          </div>
                        );
                      })
                    )}
                  </div>
                </div>
              </div>

              <div>
                <div className="flex gap-2 pt-[4px] pb-[4px]">
                  {loading ? (
                    <Skeleton
                      width={189}
                      height={22}
                      baseColor={theme === "dark" ? "#0e0e12" : "#f9f9f9"}
                      highlightColor={theme === "dark" ? "#1a1a1a" : "#ffffff"}
                    />
                  ) : (
                    <>
                      <p className="!text-[#5F5E5E]  text-[11px] ">
                        INFORMATION
                      </p>
                      <img src="static/check.svg" width={15} alt="check" />
                    </>
                  )}
                </div>

                <div className="mb-2">
                  <div className="text-left w-full md:flex flex-col gap-2">
                    {loading ? (
                      <>
                        <Skeleton
                          width={189}
                          height={22}
                          baseColor={theme === "dark" ? "#0e0e12" : "#f9f9f9"}
                          highlightColor={
                            theme === "dark" ? "#1a1a1a" : "#ffffff"
                          }
                        />
                        <Skeleton
                          width={189}
                          height={22}
                          baseColor={theme === "dark" ? "#0e0e12" : "#f9f9f9"}
                          highlightColor={
                            theme === "dark" ? "#1a1a1a" : "#ffffff"
                          }
                        />
                        <Skeleton
                          width={189}
                          height={22}
                          baseColor={theme === "dark" ? "#0e0e12" : "#f9f9f9"}
                          highlightColor={
                            theme === "dark" ? "#1a1a1a" : "#ffffff"
                          }
                        />
                      </>
                    ) : (
                      informationData.map((item, i) => {
                        const value =
                          typeof item.value === "number"
                            ? item.value
                            : item.value?.toString();

                        return (
                          <div
                            key={`${item.name}-${i}`}
                            className=" rounded  justify-between flex items-center gap-[15px]">
                            <div className="flex items-center rounded-md px-2 min-w-8 w-full h-8 shadow-[0px_0px_5px_0px_#0000001A] dark:!bg-[#121215] bg-white ">
                              <img
                                src={
                                  theme === "dark"
                                    ? "static/DarkMode/spy-round.svg"
                                    : "static/LightMode/spy-round.svg"
                                }
                                width={8}
                                alt="link"
                              />
                              <p
                                className="p_detail px-2 pt-1 pb-1 2xl:!text-[14px]  truncate dark:!bg-text-simple-dark bg-text-simple-light text-transparent "
                                style={{ backgroundClip: "text" }}>
                                {item.name}
                              </p>
                            </div>

                            <div className="rounded flex items-center justify-center min-w-16 shadow-[0px_0px_5px_0px_#0000001A] px-[10px] !bg-bg-green-gradient-light dark:!bg-bg-green-gradient-dark bg-white h-8 m-[7px] dark:bg-black 2xl:!m-0">
                              <p
                                className="p_detail  rounded-md   !text-[12px]  flex items-center justify-center  dark:bg-text-green-dark bg-text-green-light text-transparent 2xl:!text-[14px]"
                                style={{ backgroundClip: "text" }}>
                                {value}
                              </p>
                            </div>
                          </div>
                        );
                      })
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div
        className="rounded-2xl p-2 mt-6 shadow-[0px_0px_4px_0px_#0000001A]  
         dark:!bg-[#09090b] bg-white dark:border-[0.5px] border-[#353539]  
            my-3 flex justify-center items-center chartHeight h-[50%] overflow-hidden">
        {loading ? (
          <Skeleton
            width={650}
            height={280}
            baseColor={theme === "dark" ? "#0e0e12" : "#f9f9f9"}
            highlightColor={theme === "dark" ? "#1a1a1a" : "#ffffff"}
          />
        ) : (
          <img
            ref={imgRef}
            src={chartImage}
            onLoad={() => setIsChartLoader(false)}
            onError={() => setIsChartLoader(false)}
            alt="graph_image"
            className={`w-full h-full p-6 chart-padding object-contain ${
              isChartLoader ? "hidden" : ""
            }`}
          />
        )}
      </div>
    </div>
  );
};
export default ProductRightCard;
