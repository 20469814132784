import React from "react";

const Footer = () => {
  const impressumLink = "https://www.edgeecom.de/legal/imprint";
  const datenschutzLink = "https://www.edgeecom.de/datenschutz";
  const agbLink = "https://www.edgeecom.de/agb";

  return (
    <div className="fixed left-0 right-0 bottom-1 z-10 py-2 bg-background pl-20">
      <p className="text-textFooter text-center text-sm">
        2024 EDGE SOLUTIONS - All rights reserved |
        <a
          className=" ml-1 mr-1"
          href={impressumLink}
          target="_blank"
          rel="noreferrer"
        >
          Impressum
        </a>
        -
        <a
          className=" ml-1 mr-1"
          href={datenschutzLink}
          target="_blank"
          rel="noreferrer"
        >
          Datenschutz
        </a>
        -
        <a className=" ml-1" href={agbLink} target="_blank" rel="noreferrer">
          Allgemeine Geschäftsbedingungen
        </a>
      </p>
    </div>
  );
};

export default Footer;
