import React, { useState, useContext, useRef, useEffect } from "react";
import { convertToDomain, currencyFormatter, valueSign } from "../utlis/utlis";
import CommonModal from "../components/CommonModal";
import { ThemeContext } from "../context/themeContext";
import Skeleton from "react-loading-skeleton";

const productHeading = [
  { title: "margin", value: "margin" },
  { title: "profit", value: "profit" },
  { title: "buy", value: "buy_price" },
  { title: "sell", value: "sell_price" },
  { title: "asin", value: "asin" },
  { title: "ean", value: "ean" },
  { title: "margin 30d", value: "margin_30d" },
  { title: "profit 30d", value: "profit_30d" },
  { title: "sales", value: "salesdrops" },
  { title: "bsr", value: "salesrank" },
  { title: "count", value: "offer_count" },
];

const ProductContentStore = ({
  productData,
  setOffSet,
  newProductData,
  isLoader,
  initialLoading,
}) => {
  const imageRefs = useRef({});
  const { theme } = useContext(ThemeContext);
  const [copiedASIN, setCopiedASIN] = useState(null);
  const [selectedProduct, setSelectedProduct] = useState(null);
  const [imageModal, setImageModal] = useState(false);
  const [imageLoading, setImageLoading] = useState({});
  const [likedItems, setLikedItems] = useState({});
  const [showScrollToTop, setShowScrollToTop] = useState(false);
  const baseUrl = process.env.REACT_APP_API_BASE_URL; // Ensure this is set in your .env file

  useEffect(() => {
    setImageLoading((prevState) => {
      const updatedState = { ...prevState };
      newProductData.forEach((item) => {
        if (!(item.name in updatedState)) {
          updatedState[item.name] = true;
        }
      });
      return updatedState;
    });
  }, [newProductData]);

  useEffect(() => {
    if (Object.keys(imageLoading).length === 0) {
      newProductData.forEach((item) => {
        setImageLoading((prev) => ({
          ...prev,
          [item.name]: false,
        }));
      });
    }
  }, [imageLoading, newProductData]);

  const handleArbitrageOneClick = (item) => {
    // Construct the URLs with encoded parameters
    const analyzeUrl = `https://app.arbitrageone.de/analyze?via=EDGE&asin=${encodeURIComponent(
      item.asin
    )}&sell_price=${encodeURIComponent(
      item.sell_price
    )}&source_price=${encodeURIComponent(
      item.buy_price
    )}&source_url=${encodeURIComponent(
      item.buy_url
    )}&buy_url=${encodeURIComponent(item.buy_url)}`;

    const dealUrl = `${baseUrl}/deal?asin=${encodeURIComponent(
      item.asin
    )}&sell_price=${encodeURIComponent(
      item.sell_price
    )}&source_price=${encodeURIComponent(
      item.buy_price
    )}&source_url=${encodeURIComponent(
      item.buy_url
    )}&buy_url=${encodeURIComponent(item.buy_url)}`;

    // Open the analyze URL in a new tab
    window.open(analyzeUrl, "_blank");

    // Send a GET request to the deal URL without opening a new tab
    fetch(dealUrl)
      .then((response) => {
        // Handle the response if needed
        console.log("GET request sent successfully");
      })
      .catch((error) => {
        // Handle any errors
        console.error("Error sending GET request");
      });
  };

  const handleScroll = (e) => {
    const tolerance = 1;
    const bottom =
      Math.abs(
        e.target.scrollHeight - e.target.scrollTop - e.target.clientHeight
      ) <= tolerance;
    if (bottom && newProductData.length !== 0 && !isLoader) {
      setOffSet((prev) => prev + 10);
    }

    if (e.target.scrollTop > 200) {
      setShowScrollToTop(true);
    } else {
      setShowScrollToTop(false);
    }
  };
  const scrollToTop = () => {
    const container = document.querySelector(".product-section");
    container.scrollTop = 0;
  };
  const handleLike = (item) => {
    const itemId = item.name;
    const liked = likedItems[itemId] || false;
    setLikedItems((prevLikedItems) => ({
      ...prevLikedItems,
      [itemId]: !liked,
    }));
  };

  const copyAsin = (value) => {
    navigator.clipboard.writeText(value).then(() => {
      setCopiedASIN(value);
      setTimeout(() => {
        setCopiedASIN(null);
      }, 500);
    });
  };

  const handleCopy = (item, heading) => {
    if (heading.title === "asin" || heading.title === "ean") {
      copyAsin(item[heading.value]);
    }
  };

  const checkColor = (value) => {
    if (value >= 10) {
      return "dark:bg-text-green-dark bg-text-green-light text-transparent";
    } else if (value <= 10 && value >= 2) {
      return "dark:bg-text-orange-dark bg-text-orange-light text-transparent";
    } else {
      return "dark:bg-text-red-dark bg-text-red-light text-transparent";
    }
  };

  const checkBackGroundColor = (value) => {
    if (value >= 10) {
      return " !bg-bg-green-gradient-light dark:!bg-bg-green-gradient-dark bg-white  dark:bg-black";
    } else if (value <= 10 && value >= 2) {
      return "!bg-bg-orange-gradient-light dark:bg-bg-orange-gradient-dark bg-white dark:bg-black";
    } else {
      return " !bg-bg-red-gradient-light dark:bg-bg-red-gradient-dark bg-white dark:bg-black";
    }
  };

  const getColorClass = (item, heading) => {
    switch (heading.title) {
      case "margin":
      case "profit":
        return checkColor(item["margin"]);
      case "margin 30d":
      case "profit 30d":
        return checkColor(item["margin_30d"]);
      default:
        return "";
    }
  };

  const getBgColorClass = (item, heading) => {
    switch (heading.title) {
      case "margin":
      case "profit":
        return checkBackGroundColor(item["margin"]);
      case "margin 30d":
      case "profit 30d":
        return checkBackGroundColor(item["margin_30d"]);
      default:
        return "";
    }
  };

  const productClass = (heading, item) => {
    if (heading.title === "bsr" || heading.title === "asin") {
      return "dark:bg-text-simple-dark bg-text-simple-light text-transparent cursor-pointer text-textTitle bg-background  rounded shadow-[0px_0px_5px_0px_#0000001A]";
    }
    if (
      ["margin", "profit", "margin 30d", "profit 30d"].includes(heading.title)
    ) {
      return `${getColorClass(item, heading)} rounded`;
    } else {
      return "dark:bg-text-simple-dark bg-text-simple-light text-transparent bg-background  rounded shadow-[0px_0px_5px_0px_#0000001A]";
    }
  };

  const backgroudClass = (heading, item) => {
    if (heading.title === "bsr" || heading.title === "asin") {
      return "cursor-pointer text-textTitle dark:!bg-[#121215] rounded shadow-[0px_0px_5px_0px_#0000001A]";
    }
    if (
      ["margin", "profit", "margin 30d", "profit 30d"].includes(heading.title)
    ) {
      return `${getBgColorClass(item, heading)} rounded`;
    } else {
      return "dark:!bg-[#121215]  rounded shadow-[0px_0px_5px_0px_#0000001A]";
    }
  };

  const productContent = (item, heading) => {
    return (
      <div key={`${heading.title}`} className="text-left min-w-20">
        <p className="subtitle text-[11px] uppercase flex ">
          {heading.title}
          {(heading.title === "asin" || heading.title === "ean") && (
            <>
              {copiedASIN === item[heading.value] ? (
                <span className="ml-2 text-green-500 text-[10px] capitalize">
                  Kopiert!
                </span>
              ) : (
                <img
                  className="ml-2 cursor-pointer"
                  src={"/static/copy-icon.svg"}
                  alt="copy_icon"
                  width={8}
                  height={10}
                />
              )}
            </>
          )}
        </p>
        <div
          className={` rounded text-center
                         ${backgroudClass(heading, item)}`}>
          <span
            className={`block truncate p_detail px-2  pt-[4px] pb-[3px] text-[12px] text-primarytextColor
                         ${productClass(heading, item)}`}
            onClick={() => handleCopy(item, heading)}
            style={{ backgroundClip: "text" }}>
            {currencyFormatter(item[heading.value])}
            {valueSign(heading.value)}
          </span>
        </div>
      </div>
    );
  };

  const handleChartHover = (item) => {
    setSelectedProduct(item);
    setImageModal(true);
  };

  const handleChartHoverLeave = () => {
    setSelectedProduct(null);
    setImageModal(false);
  };

  const selectedProductImg =
    theme === "dark"
      ? selectedProduct?.graph_img.dark
      : selectedProduct?.graph_img.white;

  const handleImageLoad = (item) => {
    setImageLoading((prev) => ({
      ...prev,
      [item.name]: false,
    }));
  };

  const handleImageError = (item) => {
    setImageLoading((prev) => ({
      ...prev,
      [item.name]: false,
    }));
  };

  return (
    <div
      onScroll={handleScroll}
      className={`product-section text-[13px] md:w-auto card-bottom z-0 }`}>
      <div className="list gap-6">
        {initialLoading
          ? Array.from({ length: 5 }).map((_, i) => (
              <div key={`skeleton-${i}`} className="grid grid-cols-1">
                <div
                  className="rounded-2xl p-3 md:flex-row flex-col card-left-product
                  bg-white dark:bg-[#07070b] shadow-[0px_0px_4px_0px_#0000001A] dark:border-[0.5px]  border-[#353539] mx-[5px]">
                  <div className="grid grid-cols-12 items-center ">
                    <div className="col-span-12 sm:col-span-12 md:col-span-12 xl:col-span-10">
                      <div className="flex gap-4 items-center md:flex-row flex-col">
                        {/* Store Image Skeleton */}
                        <div className="right-div px-3 pr-0">
                          <Skeleton
                            width={150}
                            height={150}
                            baseColor={theme === "dark" ? "#0e0e12" : "#f2f2f2"}
                            highlightColor={
                              theme === "dark" ? "#1a1a1a" : "#ffffff"
                            }
                            className="md:h-[130px] h-[150px] w-[150px] object-contain bg-white rounded-2xl shadow-[0px_0px_5px_0px_#0000001A]"
                          />
                        </div>
                        {/* Amazon Image Skeleton */}
                        <div className="right-div pr-1">
                          <Skeleton
                            width={150}
                            height={150}
                            baseColor={theme === "dark" ? "#0e0e12" : "#f2f2f2"}
                            highlightColor={
                              theme === "dark" ? "#1a1a1a" : "#ffffff"
                            }
                            className="md:h-[130px] h-[150px] w-[150px] object-contain bg-white rounded-2xl shadow-[0px_0px_5px_0px_#0000001A]"
                          />
                        </div>
                        <div className="left-div">
                          {/* Product Title Skeleton */}
                          <div className="text-textTitle list-none capitalize flex flex-wrap items-center">
                            <div className="flex flex-wrap items-center justify-between lg:gap-2">
                              <Skeleton
                                width={200}
                                height={20}
                                baseColor={
                                  theme === "dark" ? "#0e0e12" : "#f2f2f2"
                                }
                                highlightColor={
                                  theme === "dark" ? "#1a1a1a" : "#ffffff"
                                }
                              />
                              <Skeleton
                                width={80}
                                height={15}
                                baseColor={
                                  theme === "dark" ? "#0e0e12" : "#f2f2f2"
                                }
                                highlightColor={
                                  theme === "dark" ? "#1a1a1a" : "#ffffff"
                                }
                              />
                            </div>
                          </div>
                          {/* Product Details Skeleton */}
                          <div>
                            <div className="flex gap-4 flex-wrap">
                              {Array.from({ length: 6 }).map((_, idx) => (
                                <div
                                  key={`detail-${idx}`}
                                  className="text-left min-w-20">
                                  <p className="subtitle text-[11px] uppercase flex">
                                    <Skeleton
                                      width={50}
                                      height={10}
                                      baseColor={
                                        theme === "dark" ? "#0e0e12" : "#f2f2f2"
                                      }
                                      highlightColor={
                                        theme === "dark" ? "#1a1a1a" : "#ffffff"
                                      }
                                    />
                                  </p>
                                  <div className="rounded text-center">
                                    <Skeleton
                                      width={80}
                                      height={20}
                                      baseColor={
                                        theme === "dark" ? "#0e0e12" : "#f2f2f2"
                                      }
                                      highlightColor={
                                        theme === "dark" ? "#1a1a1a" : "#ffffff"
                                      }
                                    />
                                  </div>
                                </div>
                              ))}
                            </div>
                            <div className="flex gap-4 mt-1 flex-wrap items-end">
                              {Array.from({ length: 5 }).map((_, idx) => (
                                <div
                                  key={`detail2-${idx}`}
                                  className="text-left min-w-20">
                                  <p className="subtitle text-[11px] uppercase flex">
                                    <Skeleton
                                      width={50}
                                      height={10}
                                      baseColor={
                                        theme === "dark" ? "#0e0e12" : "#f2f2f2"
                                      }
                                      highlightColor={
                                        theme === "dark" ? "#1a1a1a" : "#ffffff"
                                      }
                                    />
                                  </p>
                                  <div className="rounded text-center">
                                    <Skeleton
                                      width={80}
                                      height={20}
                                      baseColor={
                                        theme === "dark" ? "#0e0e12" : "#f2f2f2"
                                      }
                                      highlightColor={
                                        theme === "dark" ? "#1a1a1a" : "#ffffff"
                                      }
                                    />
                                  </div>
                                </div>
                              ))}
                              {/* Buy Button Skeleton */}
                              <div className="text-left">
                                <p className="subtitle text-[11px] uppercase">
                                  <Skeleton
                                    width={30}
                                    height={10}
                                    baseColor={
                                      theme === "dark" ? "#0e0e12" : "#f2f2f2"
                                    }
                                    highlightColor={
                                      theme === "dark" ? "#1a1a1a" : "#ffffff"
                                    }
                                  />
                                </p>
                                <div className=" rounded text-center">
                                  <Skeleton
                                    width={80}
                                    height={30}
                                    baseColor={
                                      theme === "dark" ? "#0e0e12" : "#f2f2f2"
                                    }
                                    highlightColor={
                                      theme === "dark" ? "#1a1a1a" : "#ffffff"
                                    }
                                  />
                                </div>
                              </div>
                              {/* Sell Button Skeleton */}
                              <div className="text-left">
                                <p className="subtitle text-[11px] uppercase">
                                  <Skeleton
                                    width={30}
                                    height={10}
                                    baseColor={
                                      theme === "dark" ? "#0e0e12" : "#f2f2f2"
                                    }
                                    highlightColor={
                                      theme === "dark" ? "#1a1a1a" : "#ffffff"
                                    }
                                  />
                                </p>
                                <div className="rounded text-center">
                                  <Skeleton
                                    width={80}
                                    height={30}
                                    baseColor={
                                      theme === "dark" ? "#0e0e12" : "#f2f2f2"
                                    }
                                    highlightColor={
                                      theme === "dark" ? "#1a1a1a" : "#ffffff"
                                    }
                                  />
                                </div>
                              </div>
                              {/* Link Button Skeleton */}
                              <div className="text-left">
                                <Skeleton
                                  width={30}
                                  height={30}
                                  baseColor={
                                    theme === "dark" ? "#0e0e12" : "#f2f2f2"
                                  }
                                  highlightColor={
                                    theme === "dark" ? "#1a1a1a" : "#ffffff"
                                  }
                                />
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    {/* Graph Image Skeleton */}
                    <div
                      className={`col-span-12 sm:col-span-12 md:col-span-12 xl:col-span-2 flex justify-center relative z-[999]`}>
                      <Skeleton
                        width={200}
                        height={150}
                        baseColor={theme === "dark" ? "#0e0e12" : "#f2f2f2"}
                        highlightColor={
                          theme === "dark" ? "#1a1a1a" : "#ffffff"
                        }
                      />
                    </div>
                  </div>
                </div>
              </div>
            ))
          : productData.map((item, i) => {
              const buyUrl = convertToDomain(item.buy_url);
              const sellUrl = convertToDomain(item.sell_url);

              const graphImg =
                theme === "dark"
                  ? item.graph_img.dark || "static/NotAvailableChart-Dark.png"
                  : item.graph_img.white ||
                    "static/NotAvailableChart-White.png";

              return (
                <div key={`${item.name}-${i}`} className="grid grid-cols-1">
                  <div
                    className=" rounded-2xl   p-3  md:flex-row flex-col card-left-product
                  bg-white dark:bg-[#07070b] shadow-[0px_0px_4px_0px_#0000001A] dark:border-[0.5px]  border-[#353539] mx-[5px]">
                    <div className=" gap-1 justify-end hidden">
                      <div>
                        <img
                          src={
                            theme === "dark"
                              ? "static/DarkMode/hide.svg"
                              : "static/LightMode/hide.svg"
                          }
                          alt="hide"
                          className="h-5 w-5 cursor-pointer  "
                        />
                      </div>
                      <div>
                        <img
                          src={
                            likedItems[item.name]
                              ? theme === "dark"
                                ? "static/LightMode/like-filled.svg"
                                : "static/LightMode/like-filled.svg"
                              : theme === "dark"
                              ? "static/DarkMode/like.svg"
                              : "static/LightMode/like.svg"
                          }
                          alt="hide"
                          className="h-5 w-5 cursor-pointer "
                          onClick={() => handleLike(item)}
                        />
                      </div>
                    </div>
                    <div className="grid grid-cols-12 items-center ">
                      <div className="col-span-12 sm:col-span-12 md:col-span-12 xl:col-span-10">
                        <div className="flex gap-4 items-center md:flex-row flex-col">
                          <div className="right-div px-3 pr-0">
                            <img
                              src={item.img_shop}
                              alt="product_img"
                              className="md:h-[130px] h-[150px] w-[150px] object-contain bg-white rounded-2xl  shadow-[0px_0px_5px_0px_#0000001A]"
                            />
                          </div>

                          <div className="right-div pr-1 ">
                            <img
                              src={item.img_amz}
                              alt="product_img"
                              className="md:h-[130px] h-[150px] w-[150px] object-contain bg-white rounded-2xl
                          
                          shadow-[0px_0px_5px_0px_#0000001A]
                          "
                            />
                          </div>
                          <div className="left-div ">
                            <div className="text-textTitle list-none capitalize flex flex-wrap items-center">
                              <div className="flex flex-wrap items-center justify-between lg:gap-2">
                                <p
                                  className="text-[18px] font-medium text-ellipsis"
                                  id="title">
                                  {item.name.length > 65
                                    ? item.name.slice(0, 65) + "..."
                                    : item.name}
                                </p>

                                <p className="text-[13px] text-textPrimary/50">
                                  {item.timestamp}
                                </p>
                              </div>
                            </div>
                            <div>
                              <div className="flex gap-4 flex-wrap">
                                {productHeading
                                  .slice(0, 6)
                                  .map((heading) =>
                                    productContent(item, heading)
                                  )}
                              </div>
                              <div className="flex gap-4 mt-1 flex-wrap items-end">
                                {productHeading
                                  .slice(6, 11)
                                  .map((heading) =>
                                    productContent(item, heading)
                                  )}

                                <div className="text-left">
                                  <p className="subtitle text-[11px] uppercase">
                                    buy
                                  </p>
                                  <div className="bg-gradient-to-br from-primary to-main rounded text-center">
                                    <button
                                      className="w-full truncate p_detail px-2 py-1 text-[12px]  text-text border-none"
                                      onClick={(e) => {
                                        window.open(item.buy_url, "_blank");
                                        e.currentTarget.blur(); // Remove focus
                                      }}>
                                      {buyUrl}
                                    </button>
                                  </div>
                                </div>

                                <div className="text-left">
                                  <p className="subtitle text-[11px] uppercase">
                                    sell
                                  </p>
                                  <div className="rounded bg-custom-gradient-primary-light dark:bg-bg-text-amazone-dark border-[1px] border-primary text-center">
                                    <button
                                      className="w-full truncate file:p_detail px-2 py-1 text-[12px] text-primary
                                  
                                  "
                                      onClick={(e) => {
                                        window.open(item.sell_url, "_blank");
                                        e.currentTarget.blur(); // Remove focus
                                      }}>
                                      {sellUrl}
                                    </button>
                                  </div>
                                </div>

                                <div className="text-left">
                                  <div
                                    className="rounded dark:bg-bg-gradient-primary-dark bg-bg-gradient-primary-light
                               border-primary text-center">
                                    <button
                                      onClick={(e) => {
                                        window.open(item.buy_url, "_blank");
                                        window.open(item.sell_url, "_blank");
                                        e.currentTarget.blur(); // Remove focus
                                      }}
                                      className="flex items-center">
                                      <img
                                        src={
                                          theme === "dark"
                                            ? "static/DarkMode/link.svg"
                                            : "static/LightMode/link.svg"
                                        }
                                        alt="chart"
                                        className="w-6 h-6 p-[6px]"
                                      />
                                    </button>
                                  </div>
                                </div>

                                <div className="group/arbitrageOne arbitrageOne text-left relative">
                                  <div
                                    className="rounded dark:bg-bg-gradient-primary-dark bg-bg-gradient-primary-light
                                     border-primary text-center">
                                    <button
                                      onClick={(e) => {
                                        handleArbitrageOneClick(item);
                                        e.currentTarget.blur(); // Remove focus
                                      }}
                                      className="flex items-center">
                                      <img
                                        src={
                                          theme === "dark"
                                            ? "static/DarkMode/arbitrageone-black.svg"
                                            : "static/LightMode/arbitrageone-white.svg"
                                        }
                                        alt="chart"
                                        className="w-6 h-6 p-[6px]"
                                      />
                                    </button>
                                  </div>
                                  <span className="absolute top-[120%] -left-full sm:left-auto sm:right-0  lg:left-[30px] lg:top-[-6px] z-[9999] block w-fit h-fit bg-[#222226] border border-solid  border-[#36363a] rounded-[4px] text-[#bfbfbf] text-[15px] leading-[22.5px] font-normal text-center py-[6px] px-[12px] opacity-0 invisible pointer-events-none transition-opacity duration-150 group-hover/arbitrageOne:opacity-100 group-hover/arbitrageOne:visible group-hover/arbitrageOne:pointer-events-auto">
                                    ArbitrageOne
                                  </span>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>

                      <div
                        className={`col-span-12 sm:col-span-12 md:col-span-12 xl:col-span-2 flex justify-center relative z-[999]`}>
                        {imageLoading[item.name] ? (
                          <div className="flex w-full h-full items-center justify-center">
                            <i className="animate-spin bx bx-loader-alt text-4xl text-primary absolute"></i>
                          </div>
                        ) : null}
                        <img
                          ref={(el) => (imageRefs.current[item.name] = el)}
                          className={`h-full w-96 cursor-pointer ${
                            imageLoading[item.name] ? "hidden" : ""
                          }`}
                          src={graphImg}
                          alt="product_img"
                          onLoad={() => handleImageLoad(item)}
                          onError={() => handleImageError(item)}
                          onClick={() => {
                            if (window.innerWidth < 1280)
                              handleChartHover(item);
                          }}
                          onMouseEnter={() => {
                            if (window.innerWidth >= 1280)
                              handleChartHover(item);
                          }}
                          onMouseLeave={() => {
                            if (window.innerWidth >= 1280)
                              handleChartHoverLeave();
                          }}
                        />
                      </div>
                    </div>
                  </div>
                </div>
              );
            })}
        {isLoader && !initialLoading && (
          <div className="flex justify-center items-center mt-4">
            <i className="animate-spin bx bx-loader-alt text-4xl text-primary"></i>
          </div>
        )}
        <div className="flex justify-center items-center mt-2 mb-20">
          <div className="flex justify-center items-center mt-2 mb-20">
            {newProductData.length === 0 && !isLoader && (
              <p className="text-textTitle">Keine weiteren Daten gefunden</p>
            )}
          </div>
        </div>
      </div>
      {showScrollToTop && (
        <button
          className="fixed bottom-5 right-5  p-2 rounded dark:!bg-back-top-dark z-[999999] bg-[#D7E6FF]"
          onClick={scrollToTop}>
          <img
            src={
              theme === "dark"
                ? "static/DarkMode/QuickIcon_dark.svg"
                : "static/LightMode/QuickIcon_light.svg"
            }
            width={18}
            height={18}
            alt="modeIcon"
          />
        </button>
      )}
      <CommonModal
        isOpen={imageModal}
        closeModal={() => setImageModal(false)}
        overlayWidth={window.innerWidth < 1280 ? "100%" : "80%"}
        isChartModal>
        <div className="p-4 w-[300px] sm:w-[510px] overflow-auto">
          <div
            onClick={() => setImageModal(false)}
            className="p-4 pr-0 pt-0 flex justify-end items-center cursor-pointer">
            <img
              src={
                theme === "dark"
                  ? "static/close-1.svg"
                  : "static/LightMode/close-2.svg"
              }
              width={15}
              alt="close"
            />
          </div>
          <img
            className="h-full w-[480px] "
            src={selectedProductImg}
            alt="product_img"
          />
        </div>
      </CommonModal>
    </div>
  );
};
export default ProductContentStore;
