import React from "react";
import { ThemeContext } from "../context/themeContext";
import { useContext } from "react";
import SubHeader from "./SubHeader";
import Layout from "../layout/Layout";
import { useNavigate } from "react-router-dom";

const ErrorPage = () => {
  const { theme } = useContext(ThemeContext);
  const navigate = useNavigate();

  const imageSrc =
    theme === "dark"
      ? "static/DarkMode/errorpage-dark.svg"
      : "static/LightMode/errorpage-light.svg";

  return (
    <Layout>
      <section className="home-section bg-background">
        <div className="text border-b-[1px] border-borderBottom">
          <SubHeader date>
            <p className="title text-textTitle">
              Es gab einen
              <span className="text-primary ml-1">Fehler!</span>
            </p>
          </SubHeader>
        </div>
        <div
          className="mt-20 flex justify-center items-center flex-col gap-4 "
          style={{
            height: "calc(100vh - 297px)",
          }}
        >
          <div className="flex justify-center items-center">
            <img
              src={imageSrc}
              alt="AmazonFlipsNoSellersEmpty"
              className="w-[500px]"
            />
          </div>

          <div className="flex justify-center items-center mt-[30px] ">
            <p
              className="text-[#B9B9B9] dark:text-[#636363] text-center md:text-[18px] max-w-[427px] text-[16px]
             tracking-wider font-medium"
            >
              Scheinbar gab es einen Fehler, kehre zur Übersicht zurück oder
              lade diese Seite neu!
            </p>
          </div>
          <div className="flex justify-center items-center ">
            <button
              className="p_detail px-6 py-2 text-[14px] text-text border-none 
            capitalize bg-gradient-to-r from-primary to-main rounded text-center  mt-4 tracking-widest font-[600]"
              onClick={() => navigate("/home")}
            >
              Startseite
            </button>
          </div>
        </div>
      </section>
    </Layout>
  );
};

export default ErrorPage;
