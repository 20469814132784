import React from "react";
import { ThemeContext } from "../context/themeContext";
import { useContext } from "react";

const NoProductsData = ({ btnTitle, description }) => {
  const { theme } = useContext(ThemeContext);

  const imageSrc =
    theme === "dark"
      ? "static/no-prodfound-bg.svg"
      : "static/no-prodfound-bg.svg";

  return (
    <div className="flex items-center justify-center h-[450px] min-h-[300px] sm:h-[650px]">
      <div
        className="h-[300px] w-full"
        style={{
          backgroundImage: `url(${imageSrc})`,
          backgroundRepeat: "no-repeat",
          backgroundSize: "contain",
          backgroundPosition: "center",
        }}
      >
        <div className="text-center pt-20">
          <p className="text-textTitle text-[18px] mb-2 font-[600] tracking-wider sm:text-[30px]">
            Keine
            <span className="bg-gradient-to-r from-primary to-main text-transparent bg-clip-text mr-2   ml-2  tracking-wider font-[600]">
              Produkte
            </span>
            gefunden!
          </p>
          <span className="flex justify-center">
            <p className="text-emptyText text-center text-[14px] md:text-[16px] tracking-wide w-[540px]">
              {description}
            </p>
          </span>
          <button
            onClick={() => window.location.reload()}
            className="p_detail px-6 py-2 text-[14px] text-text border-none capitalize bg-gradient-to-br from-primary to-main rounded text-center mt-4 tracking-widest font-[600]"
          >
            {btnTitle}
          </button>
        </div>
      </div>
    </div>
  );
};

export default NoProductsData;
