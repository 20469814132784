import React, { useEffect } from "react";
import ProductList from "../containers/ProductList";
import { useDispatch } from "react-redux";
import { resetStoreProducts } from "../redux/slices/filterStoreSlice";

const StoreSeach = () => {
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(resetStoreProducts());
  }, []);

  return (
    <ProductList
      application="storesearch"
      productType="storesearch_products"
      header="Store "
      subHeader="Search"
      filterSubHeader={
        <>
          <p>Steuere gezielt welche Produkte deinen Vorstellungen</p>
          <p>entsprechen und erhalte nur die besten Treffer</p>
        </>
      }
      noProductbtnTitle="Neu laden"
      noProductDesc="Wir konnten keine Produkte finden, möglicherweise liegt dies an deinen Filtern. Wähle alle Filter ab oder lade diese Seite neu!"
      isAsinsModalHide={true}
      isStorePage={true}
      asinsModalHeader={"Store"}
      asinsModalSubHeader={
        <>
          <p>Stelle hier gezielt ein, welche Stores du durchsuchen</p>
          <p>möchtest, um die besten Ergebnisse für dich zu erhalten.</p>
        </>
      }
    />
  );
};

export default StoreSeach;
