import { Route, Routes } from "react-router-dom";
import { AuthProvider } from "./context/AuthContext";
import LoginPage from "./pages/login";
import SpySearch from "./pages/SpySearch";
import Dashboard from "./pages/Dashboard";
import AmozonFlips from "./pages/AmazonFlips";
import Home from "./pages/Home";
import Toast from "./components/Toast";
import ErrorPage from "./components/ErrorPage";
import "react-toastify/dist/ReactToastify.css";
import { useContext, useEffect, useState } from "react";
import { ThemeContext } from "./context/themeContext";
import Preiswecker from "./pages/Preiswecker";
import DealHub from "./pages/DealHub";
import Guide from "./pages/Guide";
import AuthGuard from "./AuthGuard";
import { useSelector } from "react-redux";
import StoreSeach from "./pages/StoreSeach";
import "react-loading-skeleton/dist/skeleton.css";

const App = () => {
  const { themeLoad } = useContext(ThemeContext);
  const authStatus = useSelector((state) => state.auth.status);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    if (authStatus === "authorized" || authStatus === "unauthorized") {
      setLoading(false);
    }
  }, [authStatus]);

  return (
    <div className="App">
      <AuthProvider>
        <Routes>
          <Route path="/login" element={<LoginPage />} />
          <Route path="*" element={<ErrorPage />} />

          <Route element={<AuthGuard />}>
            <Route path="/" element={<Home />} />
            <Route path="/home" element={<Dashboard />} />
            <Route path="/spySearch" element={<SpySearch />} />
            <Route path="/a2aFlips" element={<AmozonFlips />} />
            <Route path="/preiswecker" element={<Preiswecker />} />
            <Route path="/dealHub" element={<DealHub />} />
            <Route path="/guide" element={<Guide />} />
            <Route path="/storeSearch" element={<StoreSeach />} />
          </Route>
        </Routes>
        <Toast />
        {(themeLoad || loading) && (
          <div className={`spinner ${loading ? "bg-background" : ""}`}>
            <i className="animate-spin bx bx-loader-alt text-4xl text-primary "></i>
          </div>
        )}
      </AuthProvider>
    </div>
  );
};

export default App;
