import Select, { components } from "react-select";
import { ThemeContext } from "../../context/themeContext";
import React, { useContext } from "react";

const ValueContainer = ({ children, SpySearch,filterModal, ...props }) => {
  const { theme } = useContext(ThemeContext);
  return (
    <components.ValueContainer {...props}>
      {props.getValue().length > 0 ? (
        <>
          <div
            className={`flex flex-wrap dark:!bg-text-filter-dark text-[14px] ${
              SpySearch ? "whitespace-nowrap" : "truncate"
            }`}
            style={{
              backgroundClip: "text",
              color:
                theme === "dark"
                  ? filterModal
                    ? "white"
                    : "transparent"
                  : filterModal
                  ? "#5F5E5EE5"
                  : "#5F5E5EE5",
            }}
          >
            <span
              className={` ${SpySearch && "max-w-[150px]w-[150px] truncate"}`}
            >
              {props
                .getValue()
                .map((option) => option.label)
                .join(", ")}
            </span>
          </div>
          {children[1]}
        </>
      ) : (
        <components.ValueContainer {...props}>
          {children}
        </components.ValueContainer>
      )}
    </components.ValueContainer>
  );
};


const FiterMultiDropdown = ({
  item,
  filterValue,
  onChange,
  minWidth,
  preset_name,
  filterModal,
  SpySearch,
}) => {
  const options = item.values.map((option) => ({
    label: option.value,
    value: option.request_value,
  }));

  const Option = (props) => {
    const { isSelected } = props;
    return (
      <components.Option {...props}>
        {isSelected && (
          <i
            className="bx bxs-circle  text-[8px] mx-[8px]"
            style={{
              marginLeft: "5px",
            }}
          ></i>
        )}
        {props.children}
      </components.Option>
    );
  };
  const value = preset_name
    ? filterValue?.["presets"]?.[preset_name] &&
      filterValue?.["presets"]?.[preset_name]?.[item.type]
    : (filterValue?.["filter"] && filterValue?.["filter"]?.[item.type]) ||
      filterValue?.[item?.type];

  const { theme } = useContext(ThemeContext);
  const customStyles = {
    control: (styles) => ({
      ...styles,
      minWidth,
      boxShadow: " 0px 0px 5px 0px #0000001A",
      border: "none",
      outline: "none",
      backgroundColor:
        theme === "dark"
          ? "#0f0f12"
          : "linear-gradient(0deg, rgba(20, 20, 23, 0.18) -135.67%, rgba(255, 255, 255, 0) 68.75%) !important",
    }),
    dropdownIndicator: (styles) => ({
      ...styles,
      padding: "0px",
      fontSize: "8px",
      color: theme === "dark" ? "#6E6F6F" : "#6E6F6F",
      width: "17px",
      marginRight: "4px",
    }),
    placeholder: (styles) => ({
      ...styles,
      backgroundClip: "text",
      color:
        theme === "dark"
          ? filterModal
            ? "white"
            : "transparent"
          : filterModal
          ? "#5F5E5EE5"
          : "#5F5E5EE5",
    }),
    menuList: (styles) => ({
      ...styles,
      maxHeight: "180px",
      overflow: "auto",
      boxShadow:
        "0 1px 3px 0 rgba(0, 0, 0, 0.1), 0 1px 2px -1px rgba(0, 0, 0, 0.1)",
      padding: "4px",
      height: "auto",
    }),
    singleValue: (styles) => ({
      ...styles,
      color:
        theme === "dark"
          ? filterModal
            ? "white"
            : "transparent"
          : filterModal
          ? "#5F5E5EE5"
          : "#5F5E5EE5",
      backgroundImage:
        theme === "dark" &&
        "linear-gradient(180deg, rgba(255, 255, 255, 0.9) 52%, rgba(153, 153, 153, 0.9) 70%)",
      backgroundClip: "text",
      fontSize: "14px",
      overflow: "hidden",
      textOverflow: "ellipsis",
      whiteSpace: "nowrap",
    }),
    menu: (styles) => ({
      ...styles,
      backgroundColor: theme === "dark" ? "black" : "white",
      color: theme === "dark" ? "white" : "black",
      width: "100%",
      maxHeight: "none",
      overflow: "hidden",
      boxShadow:
        "0 1px 3px 0 rgba(0, 0, 0, 0.1), 0 1px 2px -1px rgba(0, 0, 0, 0.1)",
      borderRadius: "4px",
    }),

    input: (styles, { isFocused }) => ({
      ...styles,
      color: theme === "dark" ? "#6E6F6F" : "#6E6F6F",
      outline: isFocused ? "none" : "none",
    }),
    option: (styles, { isFocused, isSelected }) => ({
      ...styles,

      background: isSelected
        ? theme === "dark"
          ? "linear-gradient(90deg, rgba(37, 246, 184, 0.1) 0%, rgba(24, 231, 169, 0.1) 52.5%, rgba(13, 217, 156, 0.22) 100%)"
          : "linear-gradient(90deg, rgba(215, 230, 255, 0.4) 0%, #D7E6FF 45.5%, rgba(215, 230, 255, 0.4) 100%)"
        : isFocused
        ? theme === "dark"
          ? "#202024"
          : "#F5F5F5"
        : "transparent",

      color: isSelected
        ? theme === "dark"
          ? "#58FFB9"
          : "#5793FF"
        : isFocused
        ? theme === "dark"
          ? "#6E6F6F"
          : "#6E6F6F"
        : "#808080",

      paddingRight: isSelected ? "31px" : "",

      "&:hover": {
        backgroundColor: theme === "dark" ? "#202024" : "#F5F5F5",
      },
      overflow: "hidden",
      textOverflow: "ellipsis",
      whiteSpace: "nowrap",
      textAlign: "center",
      fontSize: "12px",
      width: "100%",
      borderRadius: "4px",
    }),
  };

  return (
    <Select
      isMulti
      classNamePrefix="select"
      hideSelectedOptions={false}
      name={item.type}
      isClearable={false}
      options={options}
      className={`${
        filterModal
          ? "border-[0.5px] light:border-[#F2F2F2] dark:border-none rounded "
          : ""
      }`}
      onChange={(newValue) => onChange(item, newValue, preset_name)}
      value={value || []}
      placeholder={item.name}
      components={{
        IndicatorSeparator: () => null,
        ValueContainer: (props) => (
          <ValueContainer
            {...props}
            filterModal={filterModal}
            SpySearch={SpySearch}
          />
        ),

        Option,
      }}
      styles={customStyles}
      classNames={{
        control: () =>
          `text-center ${
            filterModal
              ? "dark:bg-filterbg-dark"
              : "dark:!bg-bg-filter-bg-color-dark bg-white "
          }`,
        valueContainer: () => `!text-textTitle ${SpySearch && "!flex-nowrap"}`,
        placeholder: () => "dark:!bg-text-filter-dark   text-[14px]",
      }}
    />
  );
};

export default FiterMultiDropdown;
