import React, { useContext, useState } from "react";
import { convertToDomain, currencyFormatter, valueSign } from "../utlis/utlis";
import { productHeading } from "../lib/mock/productCardMock";
import { ThemeContext } from "../context/themeContext";

const SpyProductCard = ({
  productData,
  setSelectedProduct,
  setOffSet,
  isLoader,
  newProductData,
  selectedProduct,
}) => {
  const { theme } = useContext(ThemeContext);
  const [copiedASIN, setCopiedASIN] = useState(null);
  const [showScrollToTop, setShowScrollToTop] = useState(false);
  const baseUrl = process.env.REACT_APP_API_BASE_URL; // Ensure this is set in your .env file

  const handleArbitrageOneClick = (item) => {
    // Construct the URLs with encoded parameters
    const analyzeUrl = `https://app.arbitrageone.de/analyze?via=EDGE&asin=${encodeURIComponent(
      item.asin
    )}&sell_price=${encodeURIComponent(
      item.sell_price
    )}&source_price=${encodeURIComponent(
      item.buy_price
    )}&source_url=${encodeURIComponent(
      item.buy_url
    )}&buy_url=${encodeURIComponent(item.buy_url)}`;

    const dealUrl = `${baseUrl}/deal?asin=${encodeURIComponent(
      item.asin
    )}&sell_price=${encodeURIComponent(
      item.sell_price
    )}&source_price=${encodeURIComponent(
      item.buy_price
    )}&source_url=${encodeURIComponent(
      item.buy_url
    )}&buy_url=${encodeURIComponent(item.buy_url)}`;

    // Open the analyze URL in a new tab
    window.open(analyzeUrl, "_blank");

    // Send a GET request to the deal URL without opening a new tab
    fetch(dealUrl)
      .then((response) => {
        // Handle the response if needed
        console.log("GET request sent successfully");
      })
      .catch((error) => {
        // Handle any errors
        console.error("Error sending GET request");
      });
  };

  const handleProduct = (item) => {
    setSelectedProduct(item);
  };

  const handleScroll = (e) => {
    const tolerance = 1;
    const bottom =
      Math.abs(
        e.target.scrollHeight - e.target.scrollTop - e.target.clientHeight
      ) <= tolerance;
    if (bottom && newProductData.length !== 0 && !isLoader) {
      setOffSet((prev) => prev + 10);
    }

    if (e.target.scrollTop > 200) {
      setShowScrollToTop(true);
    } else {
      setShowScrollToTop(false);
    }
  };
  const scrollToTop = () => {
    const container = document.querySelector(".product-section");
    container.scrollTop = 0;
  };

  const checkColor = (value) => {
    if (value >= 10) {
      return "dark:bg-text-green-dark bg-text-green-light text-transparent";
    } else if (value <= 10 && value >= 2) {
      return "dark:bg-text-orange-dark bg-text-orange-light text-transparent";
    } else {
      return "dark:bg-text-red-dark bg-text-red-light text-transparent";
    }
  };

  const checkBackGroundColor = (value) => {
    if (value >= 10) {
      return " !bg-bg-green-gradient-light dark:!bg-bg-green-gradient-dark bg-white  dark:bg-black";
    } else if (value <= 10 && value >= 2) {
      return "!bg-bg-orange-gradient-light dark:bg-bg-orange-gradient-dark bg-white dark:bg-black";
    } else {
      return " !bg-bg-red-gradient-light dark:bg-bg-red-gradient-dark bg-white dark:bg-black";
    }
  };

  const getColorClass = (item, heading) => {
    switch (heading.title) {
      case "margin":
      case "profit":
        return checkColor(item["margin"]);
      case "margin 30d":
      case "profit 30d":
        return checkColor(item["margin_30d"]);
      default:
        return "";
    }
  };

  const getBgColorClass = (item, heading) => {
    switch (heading.title) {
      case "margin":
      case "profit":
        return checkBackGroundColor(item["margin"]);
      case "margin 30d":
      case "profit 30d":
        return checkBackGroundColor(item["margin_30d"]);
      default:
        return "";
    }
  };

  const productClass = (heading, item) => {
    if (heading.title === "bsr" || heading.title === "asin") {
      return "dark:bg-text-simple-dark bg-text-simple-light text-transparent cursor-pointer text-textTitle bg-background  rounded shadow-[0px_0px_5px_0px_#0000001A]";
    }
    if (
      ["margin", "profit", "margin 30d", "profit 30d"].includes(heading.title)
    ) {
      return `${getColorClass(item, heading)} rounded`;
    } else {
      return "dark:bg-text-simple-dark bg-text-simple-light text-transparent  rounded shadow-[0px_0px_5px_0px_#0000001A]";
    }
  };

  const backgroudClass = (heading, item) => {
    if (heading.title === "bsr" || heading.title === "asin") {
      return "cursor-pointer text-textTitle dark:!bg-[#121215] rounded shadow-[0px_0px_5px_0px_#0000001A]";
    }
    if (
      ["margin", "profit", "margin 30d", "profit 30d"].includes(heading.title)
    ) {
      return `${getBgColorClass(item, heading)} rounded`;
    } else {
      return "dark:!bg-[#121215]  rounded shadow-[0px_0px_5px_0px_#0000001A]";
    }
  };

  const copyAsin = (value) => {
    navigator.clipboard.writeText(value).then(() => {
      setCopiedASIN(value);
      setTimeout(() => {
        setCopiedASIN(null);
      }, 500);
    });
  };

  const handleCopy = (item, heading) => {
    if (heading.title === "asin" || heading.title === "bsr") {
      copyAsin(item[heading.value]);
    }
  };

  const productContent = (item, heading) => {
    return (
      <div key={heading.title} className="text-left min-w-20">
        <div className="flex items-center">
          <p className="subtitle text-[11px] uppercase text-[#5F5E5E]">
            {heading.title}
          </p>
          {heading.title === "bsr" && (
            <>
              {copiedASIN === item[heading.value] ? (
                <span className="ml-2 text-green-500 text-[10px] capitalize">
                  Kopiert!
                </span>
              ) : (
                <img
                  className="ml-2 cursor-pointer"
                  src={"/static/copy-icon.svg"}
                  alt="copy_icon"
                  width={8}
                  height={10}
                />
              )}
            </>
          )}
        </div>

        <div
          className={` rounded text-center
                         ${backgroudClass(heading, item)}`}>
          <span
            className={`block truncate p_detail px-2 pt-[4px] pb-[3px] text-[12px] text-primarytextColor
                         ${productClass(heading, item)}`}
            onClick={() => handleCopy(item, heading)}
            style={{ backgroundClip: "text" }}>
            {currencyFormatter(item[heading.value])}
            {valueSign(heading.value)}
          </span>
        </div>
      </div>
    );
  };

  return (
    <div
      onScroll={handleScroll}
      className="product-section text-[13px] md:w-auto card-bottom">
      <div className="list gap-6">
        {productData?.map((item, i) => {
          const buyUrl = convertToDomain(item.buy_url);
          const sellUrl = convertToDomain(item.sell_url);

          return (
            <div key={`${item.name}-${i}`} className="relative  rounded-2xl">
              <div
                className={`flex items-center rounded-2xl p-3 md:flex-row flex-col mx-[5px]   
                  card-left-product bg-white dark:bg-[#07070b] shadow-[0px_0px_4px_0px_#0000001A] dark:border-[0.5px]  
                  border-[#353539] ${
                    selectedProduct.name === item.name ? "border-selected " : ""
                  }`}>
                <div className="right-div pr-5 px-3">
                  <img
                    src={item.img}
                    alt="product_img"
                    className="md:h-[130px] h-[150px] w-[150px] object-contain bg-white rounded-2xl"
                  />
                </div>
                <div className="left-div">
                  <div
                    className="text-cardTitle list-none cursor-pointer capitalize flex flex-wrap justify-between items-center"
                    onClick={() => handleProduct(item)}>
                    <p
                      className="text-[18px] font-medium text-ellipsis"
                      id="title">
                      {item.name.length > 65
                        ? item.name.slice(0, 65) + "..."
                        : item.name}
                    </p>

                    <p className="text-[13px] text-textPrimary/50">
                      {item.timestamp}
                    </p>
                  </div>

                  <div className="flex gap-4 flex-wrap">
                    {productHeading
                      .slice(0, 5)
                      .map((heading) => productContent(item, heading))}
                  </div>

                  <div className="flex gap-4 mt-2 flex-wrap">
                    {productHeading
                      .slice(5, 8)
                      .map((heading) => productContent(item, heading))}

                    <div className="text-left">
                      <p className="subtitle text-[11px] uppercase">buy</p>
                      <div className="bg-gradient-to-br from-primary to-main rounded text-center">
                        <button
                          className="w-full truncate p_detail px-2 pt-1 pb-1 text-[12px] text-text border-none"
                          onClick={() => window.open(item.buy_url, "_blank")}>
                          {buyUrl}
                        </button>
                      </div>
                    </div>

                    <div className="text-left">
                      <p className="subtitle text-[11px] uppercase">sell</p>

                      <div className="rounded bg-custom-gradient-primary-light dark:bg-bg-text-amazone-dark border-[1px] border-primary text-center">
                        <button
                          className="w-full truncate file:p_detail px-2 pt-1 pb-1 text-[12px] text-primary"
                          onClick={() => window.open(item.sell_url, "_blank")}>
                          {sellUrl}
                        </button>
                      </div>
                    </div>

                    <div className="group/arbitrageOne arbitrageOne text-left relative">
                      <p className="subtitle text-[11px] uppercase">A1</p>

                      <div
                        className="rounded dark:bg-bg-gradient-primary-dark bg-bg-gradient-primary-light
                                     border-primary text-center">
                        <button
                          onClick={(e) => {
                            handleArbitrageOneClick(item);
                            e.currentTarget.blur(); // Remove focus
                          }}
                          className="flex items-center">
                          <img
                            src={
                              theme === "dark"
                                ? "static/DarkMode/arbitrageone-black.svg"
                                : "static/LightMode/arbitrageone-white.svg"
                            }
                            alt="chart"
                            className="w-7 h-7 p-[6px]"
                          />
                        </button>
                      </div>
                      <span className="absolute top-[120%] -left-full sm:left-auto sm:right-0  lg:left-[30px] lg:top-[-6px] z-[9999] block w-fit h-fit bg-[#222226] border border-solid  border-[#36363a] rounded-[4px] text-[#bfbfbf] text-[15px] leading-[22.5px] font-normal text-center py-[6px] px-[12px] opacity-0 invisible pointer-events-none transition-opacity duration-150 group-hover/arbitrageOne:opacity-100 group-hover/arbitrageOne:visible group-hover/arbitrageOne:pointer-events-auto">
                        ArbitrageOne
                      </span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          );
        })}
      </div>
      {showScrollToTop && (
        <button
          className="fixed bottom-5 right-5  p-2 rounded dark:!bg-back-top-dark z-[999999] bg-[#D7E6FF]"
          onClick={scrollToTop}>
          <img
            src={
              theme === "dark"
                ? "static/DarkMode/QuickIcon_dark.svg"
                : "static/LightMode/QuickIcon_light.svg"
            }
            width={18}
            height={18}
            alt="modeIcon"
          />
        </button>
      )}
      <div className="flex justify-center items-center my-3 mb-10 2xl:mb-10 xl:mb-15 lg:mb-20 ">
        {isLoader ? (
          <i className="animate-spin bx bx-loader-alt text-4xl text-primary"></i>
        ) : newProductData.length === 0 ? (
          <p className="text-textTitle">Keine weiteren Daten gefunden</p>
        ) : null}
      </div>
    </div>
  );
};
export default SpyProductCard;
