import { ThemeContext } from "../../context/themeContext";
import Select, { components } from "react-select";
import React, { useContext } from "react";

const FilterDropdown = ({
  options,
  placeholder,
  width,
  onChange,
  value,
  isMulti,
  filterModal,
}) => {
  const { theme } = useContext(ThemeContext);

  const Option = (props) => {
    const { isSelected } = props;
    return (
      <components.Option {...props}>
        {isSelected && (
          <i
            className="bx bxs-circle  text-[8px] mx-[8px]"
            style={{
              marginLeft: "5px",
            }}
          ></i>
        )}
        {props.children}
      </components.Option>
    );
  };

  const customStyles = {
    menuPortal: (styles) => ({
      ...styles,
      zIndex: 100,
    }),
    control: (styles, { isFocused }) => ({
      ...styles,
      boxShadow: " 0px 0px 5px 0px #0000001A",
      backgroundColor:
        theme === "dark"
          ? "#0f0f12"
          : "linear-gradient(0deg, rgba(20, 20, 23, 0.18) -135.67%, rgba(255, 255, 255, 0) 68.75%) !important",
      border: "none",
      padding: "6px 2px",
      outline: "none",
      width,
    }),

    placeholder: (styles) => ({
      ...styles,
      backgroundClip: "text",
      color:
        theme === "dark"
          ? filterModal
            ? "white"
            : "transparent"
          : filterModal
          ? "#5F5E5EE5"
          : "#5F5E5EE5",
    }),
    menu: (styles) => ({
      ...styles,
      backgroundColor: theme === "dark" ? "black" : "white",
      color: theme === "dark" ? "white" : "black",
      width: width ?? "100%",
      maxHeight: "none",
      overflow: "hidden",
      boxShadow:
        "0 1px 3px 0 rgba(0, 0, 0, 0.1), 0 1px 2px -1px rgba(0, 0, 0, 0.1)",
      borderRadius: "4px",
    }),

    menuList: (styles) => ({
      ...styles,
      maxHeight: "180px",
      overflow: "auto",
      boxShadow:
        "0 1px 3px 0 rgba(0, 0, 0, 0.1), 0 1px 2px -1px rgba(0, 0, 0, 0.1)",
      padding: "4px",
      height: "auto",
    }),

    singleValue: (styles) => ({
      ...styles,
      color:
        theme === "dark"
          ? filterModal
            ? "white"
            : "transparent"
          : filterModal
          ? "#5F5E5EE5"
          : "#5F5E5EE5",
      fontSize: "14px",
      backgroundImage:
        theme === "dark" &&
        "linear-gradient(180deg, rgba(255, 255, 255, 0.9) 52%, rgba(153, 153, 153, 0.9) 70%)",
      backgroundClip: "text",
      overflow: "hidden",
      textOverflow: "ellipsis",
      whiteSpace: "nowrap",
    }),

    option: (styles, { isFocused, isSelected }) => ({
      ...styles,

      background: isSelected
        ? theme === "dark"
          ? "linear-gradient(90deg, rgba(37, 246, 184, 0.1) 0%, rgba(24, 231, 169, 0.1) 52.5%, rgba(13, 217, 156, 0.22) 100%)"
          : "linear-gradient(90deg, rgba(215, 230, 255, 0.4) 0%, #D7E6FF 45.5%, rgba(215, 230, 255, 0.4) 100%)"
        : isFocused
        ? theme === "dark"
          ? "#202024"
          : "#F5F5F5"
        : "transparent",

      color: isSelected
        ? theme === "dark"
          ? "#58FFB9"
          : "#5793FF"
        : isFocused
        ? theme === "dark"
          ? "#6E6F6F"
          : "#6E6F6F"
        : "#808080",

      paddingRight: isSelected ? "31px" : "",

      "&:hover": {
        backgroundColor: theme === "dark" ? "#202024" : "#F5F5F5",
      },
      overflow: "hidden",
      textOverflow: "ellipsis",
      whiteSpace: "nowrap",
      textAlign: "center",
      fontSize: "12px",
      width: "100%",
      borderRadius: "4px",
    }),

    input: (styles, { isFocused }) => ({
      ...styles,
      color: theme === "dark" ? "#6E6F6F" : "#6E6F6F",
      outline: isFocused ? "none" : "none",
    }),
    dropdownIndicator: (styles) => ({
      ...styles,
      padding: "0px",
      fontSize: "8px",
      color: theme === "dark" ? "#6E6F6F" : "#6E6F6F",
      width: "17px",
      marginRight: "4px",
    }),
    indicatorSeparator: (provided, state) => ({
      ...provided,
      display: "none",
    }),
  };

  return (
    <Select
      isMulti={isMulti}
      options={options}
      placeholder={placeholder || "Select"}
      className={`${
        filterModal
          ? "border-[0.5px] light:border-[#F2F2F2] dark:border-none rounded "
          : ""
      }`}
      classNamePrefix="select"
      isSearchable={false}
      onChange={onChange}
      value={value || null}
      menuPlacement="auto"
      menuPortalTarget={document.body}
      styles={customStyles}
      components={{ Option }}
      classNames={{
        control: () =>
          `text-center ${
            filterModal
              ? "dark:bg-filterbg-dark"
              : "dark:!bg-bg-filter-bg-color-dark bg-white "
          }`,
        placeholder: () => "dark:!bg-text-filter-dark   text-[12px]",
      }}
    />
  );
};
export default FilterDropdown;
