import React, { useEffect, useState } from "react";
import ProductList from "../containers/ProductList";
import { useDispatch } from "react-redux";
import { resetA2AProducts } from "../redux/slices/a2aProductSlice";

const Preiswecker = () => {
  const dispatch = useDispatch();
  const [tutorialModal, setTutorialModal] = useState(false);

  useEffect(() => {
    dispatch(resetA2AProducts());
  }, []);

  return (
    <ProductList
      application="pricealerts"
      productType="pricealerts_products"
      header="Shop"
      subHeader="Preiswecker"
      filterSubHeader="Deine Filter für Preiswecker"
      noProductbtnTitle="Loslegen"
      noProductDesc="Du bist dir nicht sicher wie du neue Produkte hinzufügen kannst? Dann schau dir unseren ausführlichen Guide an"
      priceAlertInfoName="price_alert_info_pricealerts"
      addPriceAlertName="add_price_alert_pricealerts"
      deletePriceAlertName="delete_price_alert_pricealerts"
      asinsModalHeader="Preiswecker"
      isTutorialModal={true}
      tutorialModal={tutorialModal}
      setTutorialModal={setTutorialModal}
      asinsModalSubHeader={
        <>
          <p>
            Lade eine CSV Datei mit Asins hoch um automatisch <br />
            Preiswecker zu stellen. Noch nie gemacht?{" "}
            <span
              className="text-primary"
              onClick={() => setTutorialModal(true)}
            >
              Tutorial
            </span>
          </p>
        </>
      }
    />
  );
};

export default Preiswecker;
