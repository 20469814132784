import React, { useContext } from "react";
import { useNavigate } from "react-router-dom";
import { ThemeContext } from "../context/themeContext";

const Card = ({ title, icon, link, selectCard, desc }) => {
  const { theme } = useContext(ThemeContext);
  const navigate = useNavigate();

  const handleStarten = (link) => {
    navigate(`/${link}`);
  };

  return (
    <div
      className={`guides flex bg-bgColor dark:border-[1px] dark:border-borderBottom h-[284px] dark:shadow ${
        selectCard
          ? "dark:bg-selected-card-dark bg-selected-card-light gap-3 "
          : "gap-6"
      }`}
      style={{
        boxShadow: theme === "light" && "0px 0px 4px 0px #0000001A",
      }}
    >
      <p
        className={` text-text font-bold ${
          selectCard
            ? "guide-select-title text-[24px] sm:text-[28px] p-0 font-[500px] dark:bg-selected-text-dark text-white dark:text-transparent"
            : "guide-title dark:bg-text-dark bg-text-light text-transparent text-[24px] sm:text-[28px] font-semibold p-0"
        }`}
        style={{ backgroundClip: "text" }}
      >
        {title}
      </p>

      {selectCard ? (
        <p
          className="text-[84px]
          
          dark:bg-selected-lable-dark bg-selected-lable-light  text-transparent font-semibold tracking-wide"
          style={{ backgroundClip: "text" }}
        >
          {desc}
        </p>
      ) : (
        <img className="h-[100px]" src={icon} alt="img" />
      )}

      {selectCard ? (
        <p
          className={`text-base text-text tracking-wider text-[14px] font-bold	${
            selectCard
              ? "guide-select-title dark:bg-selected-text-dark bg-selected-text-light text-transparent"
              : "guide-title"
          }`}
          style={{ backgroundClip: "text" }}
        >
          {link}
        </p>
      ) : (
        <button
          className="btn-doc bg-[#3E85FF26] dark:!bg-text-starten dark:bg-transparent
          text-primary tracking-wide"
          onClick={() => handleStarten(link)}
        >
          <p
            className=" text-primary text-[16px] font-[600] px-1"
            style={{ backgroundClip: "text" }}
          >
            starten
          </p>
        </button>
      )}
    </div>
  );
};

export default Card;
