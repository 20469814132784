import React from "react";
import SubHeader from "../components/SubHeader";

const DeletingConfirmation = ({ handleDelete, handleDeleteModal }) => {
  return (
    <div className="bg-background p-10 rounded-lg w-[300px] md:w-[500px] overflow-auto">
      <div className="bg-background mb-4 flex justify-between items-center">
        <div className="m-auto">
          <SubHeader>
            <p className="title text-textTitle text-[20px] font-semibold">
              Bist du dir
              <span
                className="ml-1 text-[20px] dark:bg-text-dark bg-text-light text-transparent"
                style={{ backgroundClip: "text" }}
              >
                {" "}
                sicher ?
              </span>
            </p>
          </SubHeader>
        </div>
      </div>
      <section className="text-center">
        <p className="text-emptyText pb-2 leading-5 text-[16px]">
          Hiermit bestätigst du die Löschung aller Asins, die du angelegt hast.
          Bitte beachte, dass du nur alle 7 Tage deine Asins verändern kannst.
        </p>

        <div className="flex justify-center items-center gap-4 mt-6">
          <div className="rounded text-center dark:bg-delete-btn-dark bg-delete-btn-light">
            <button
              className="p_detail
                px-3 pt-2 pb-2 w-24 text-[12px] text-text border-none font-semibold tracking-widest  dark:text-[#FF5959]"
              onClick={handleDelete}
            >
              Löschen
            </button>
          </div>
          <div>
            <div className=" bg-deleteButton rounded text-center shadow">
              <button
                className="p_detail px-3 pt-2 pb-2 w-24 text-[12px] text-deleteBtnText border-none font-semibold tracking-widest"
                onClick={handleDeleteModal}
              >
                Abbrechen
              </button>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
};

export default DeletingConfirmation;
