import React, { useContext, useEffect, useState } from "react";
import Card from "../components/Card";
import SubHeader from "../components/SubHeader";
import { ThemeContext } from "../context/themeContext";
import { selectCard } from "../lib/mock/overviewMock";
import { useSelector } from "react-redux";
import { overViewMockData } from "../lib/mock/overviewMock";
import Skeleton from "react-loading-skeleton";

const Overview = () => {
  const { theme } = useContext(ThemeContext);
  const { firstTimeLoad, setFirstTimeLoad } = useContext(ThemeContext);
  const overViewDetails = useSelector((state) => state.overview.overview);
  const homeDetails = useSelector((state) => state.auth.homeData);
  const [overViewData, setOverViewData] = useState(overViewMockData);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    if (overViewDetails && overViewDetails.length > 0) {
      setOverViewData(overViewDetails);
    }
  }, [overViewDetails]);

  useEffect(() => {
    if (homeDetails?.status === "authorized" && firstTimeLoad) {
      setTimeout(() => {
        setFirstTimeLoad(false);
      }, 1000);
    }
  }, [homeDetails, firstTimeLoad]);

  useEffect(() => {
    if (homeDetails?.status === "authorized") {
      setLoading(false);
    }
  }, [homeDetails]);

  return (
    <section className="home-section bg-background">
      <div className="text border-b-[1px] border-borderBottom">
        <SubHeader date>
          <p className="title text-textTitle font-semibold tracking-wide text-[20px]">
            Willkommen
            <span className="bg-gradient-to-br from-primary to-main bg-clip-text text-transparent ml-1 font-semibold tracking-wide">
              {homeDetails?.name}
            </span>
            <span className="ml-1">👋</span>
          </p>
        </SubHeader>
      </div>
      <div className="grid grid-cols-12 gap-6 mx-10 pt-8 mb-10">
        <div className="xl:col-span-12 col-span-12">
          {loading ? (
            <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 gap-6">
              {Array.from({ length: 6 }).map((_, index) => (
                <div
                  key={index}
                  className={`${
                    index === 0
                      ? " flex bg-bgColor opacity-5 h-[280px] dark:bg-selected-card-dark bg-selected-card-light rounded-lg"
                      : "rounded-lg"
                  }`}>
                  <Skeleton
                    height={280}
                    baseColor={theme === "dark" ? "#0e0e12" : "#f2f2f2"}
                    highlightColor={theme === "dark" ? "#1a1a1a" : "#ffffff"}
                    className="w-full h-full"
                  />
                </div>
              ))}
            </div>
          ) : (
            <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 gap-6">
              {selectCard.map((item, index) => (
                <Card
                  key={index}
                  title={item.title}
                  link={item.link}
                  desc={homeDetails?.deals_today}
                  selectCard
                />
              ))}

              {overViewData.map((item) => (
                <Card
                  key={item.title}
                  title={item.title}
                  icon={theme === "dark" ? item.icon : item.iconLight}
                  link={item.link}
                />
              ))}
            </div>
          )}
        </div>
      </div>
    </section>
  );
};

export default Overview;
