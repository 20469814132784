import React, { useContext, useEffect, useState } from "react";
import { ThemeContext } from "../../context/themeContext";

const FilterDropdownCheck = ({
  item,
  filterValue,
  preset_name,
  onChange,
  filterModal,
}) => {
  const { theme } = useContext(ThemeContext);
  const [selected, setSelected] = useState([]);
  const [filteredOptions, setFilteredOptions] = useState([]);
  const [searchTerm, setSearchTerm] = useState("");
  const handleSearchChange = (e) => {
    setSearchTerm(e.target.value);
  };
  useEffect(() => {
    if (item?.values?.length > 0) {
      const updatedOptions = item.values.map((option) => {
        return {
          label: option.value,
          value: option.request_value,
        };
      });
      setFilteredOptions(updatedOptions);
    }
  }, [item]);

  useEffect(() => {
    if (filterValue["filter"] || filterValue["presets"]) {
      const itemValue = preset_name
        ? filterValue["presets"][preset_name] &&
          filterValue["presets"][preset_name][item.type]
        : filterValue["filter"][item.type];

      const updatedItemValue = itemValue.map((item) =>
        item.value ? item.value : item
      );

      setSelected(updatedItemValue || []);
    }
  }, [filterValue, preset_name]);

  const handleCheckboxChange = (e) => {
    if (e.target.checked) {
      setSelected([...selected, e.target.value]);
      onChange(
        item,
        [...selected, e.target.value],
        e.target.value,
        preset_name
      );
    } else {
      setSelected(selected.filter((item) => item !== e.target.value));
      onChange(
        item,
        selected.filter((item) => item !== e.target.value),
        e.target.value,
        preset_name
      );
    }
  };
  const filteredResults = filteredOptions.filter((option) =>
    option.label.toLowerCase().includes(searchTerm.toLowerCase())
  );


  return (
    <div className='w-full h-full'>
      <div className='!relative'>
        <input
          type='text'
          value={searchTerm}
          onChange={handleSearchChange}
          className={`rounded  w-full p-2 pl-10  !text-dark dark:!text-white focus:outline-none border dark:border-[#1E1E22] 
          border-[#eaeaea] ${
            filterModal
              ? " dark:!bg-[#0f0f12] !bg-[#fafafa]  "
              : "bg-bg-textFeild-light dark:bg-bg-textFeild-dark "
          }`}
          style={{
            boxShadow: " 0px 0px 5px 0px #0000000D",
            backgroundColor: theme === "dark" ? "#08080b" : "#fff",
          }}
        />
        <i></i>
        <img
          src={
            theme === "dark"
              ? "static/DarkMode/search-icon-dark.svg"
              : "static/LightMode/search-icon.svg"
          }
          className={`transform -translate-y-1/2 text-gray-400  text-xl pl-1 bx bx-search absolute left-[6px] top-[21px] h-[20px] w-[20px]
          `}
          alt='search icon'
        />
        <ul
          className={`rounded   overflow-x-auto ${
            filterModal ? "h-[120px] p-1" : "h-[280px] p-3"
          }`}
          style={{
            boxShadow: "0px 0px 5px 0px #0000001A",
            border:
              theme === "light" ? "1px solid #eaeaea" : "1px solid #1E1E22",
          }}>
          {filteredResults.map((option, i) => (
            <li
              className={`flex items-center ${filterModal ? "p-1" : " p-3"}`}
              key={i}>
              <label class='custom-checkbox'>
                <input
                  type='checkbox'
                  name={option.value}
                  value={option.value}
                  checked={selected.includes(option.value)}
                  onChange={handleCheckboxChange}
                />
                <span
                  className={` ${
                    filterModal
                      ? "h-[16px] w-[16px] filterCheckmark"
                      : "h-[30px] w-[30px] checkmark"
                  }`}></span>
                <span
                  className={`text-textTitle pl-2 ${
                    filterModal ? "text-[12px]" : " font-medium"
                  }`}>
                  {option.label}
                </span>
              </label>
            </li>
          ))}
        </ul>
      </div>
    </div>
  );
};
export default FilterDropdownCheck;
