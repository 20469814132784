import React, { useState, useContext } from "react";
import CommonModal from "../../components/CommonModal";
import { ThemeContext } from "../../context/themeContext";
import CreatableSelect from "react-select/creatable";

const FiterMultiKeyword = ({
  item,
  setInputValue,
  inputValue,
  handleEnterItem,
  filterValue,
  setFilterValue,
  onChange,
  preset_name,
}) => {
  const [open, setOpen] = useState(false);

  const { theme } = useContext(ThemeContext);

  const value = preset_name
    ? filterValue?.presets?.[preset_name]?.[item.type] || []
    : filterValue?.filter?.[item.type] || [];

  const customStyles = {
    control: (styles) => ({
      ...styles,
      backgroundImage:
        theme === "light" && "0deg, #141417 0%, rgba(20, 20, 23, 0.0) 100%",
      boxShadow: "0px 0px 5px 0px rgba(0, 0, 0, 0.1)",
      backgroundColor:
        theme === "dark"
          ? "#0f0f12"
          : "linear-gradient(274.57deg, rgba(20, 20, 23, 0.203) -5.39%, rgba(255, 255, 255, 0) 112.96%)",
      border: theme === "dark" ? "unset" : "1px solid #eaeaea",
    }),
  };

  const handleRemoveValue = (option) => {
    const newValue = value.filter((item) => item.value !== option.value);

    const isPreset = preset_name !== undefined;
    const targetObject = isPreset ? "presets" : "filter";

    setFilterValue((prev) => {
      const newState = { ...prev };

      if (isPreset) {
        newState[targetObject][preset_name][item.type] = newValue;
      } else {
        newState[targetObject][item.type] = newValue;
      }

      return newState;
    });
  };

  const renderSelectedOption = (option) => {
    const colourStyles = {
      multiValue: (styles) => {
        return {
          ...styles,
          backgroundColor: theme === "dark" ? "#05BF84" : "#5793FF",
        };
      },
      multiValueLabel: (styles) => {
        return {
          ...styles,
          color: theme === "dark" ? "#000000" : "#FFFFFF",
          fontSize: "14px",
        };
      },
      multiValueRemove: (styles) => {
        return {
          ...styles,
          color: theme === "dark" ? "#05BF84" : "#5793FF",

          ":hover": {
            backgroundColor: "",
            color: "white",
          },
        };
      },
    };

    return (
      <div
        key={option.value}
        style={{ display: "flex", alignItems: "center", margin: "8px" }}
      >
        <div
          style={{
            ...colourStyles.multiValue({}),
            display: "flex",
            alignItems: "center",
            padding: "4px 8px",
            borderRadius: "4px",
          }}
        >
          <span style={colourStyles.multiValueLabel({})}>{option.label}</span>
          <span
            style={{
              marginLeft: "4px",
              cursor: "pointer",
              ...colourStyles.multiValueRemove({}),
              borderRadius: "50%",
              backgroundColor: theme === "dark" ? "#000" : "#fff",
              height: "16px",
              width: "16px",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
            onClick={() => handleRemoveValue(option)}
          >
            <img
              src={
                theme === "dark"
                  ? "static/close-1.svg"
                  : "static/LightMode/close-2.svg"
              }
              width={8}
              alt="close"
            />
          </span>
        </div>
      </div>
    );
  };

  const handleKeyDown = (event) => {
    if (!inputValue) return;

    switch (event.key) {
      case "Enter":
      case "Tab":
        setInputValue({
          ...inputValue,
          [`${item.type}`]: "",
        });
        event.preventDefault();
        handleEnterItem(item, inputValue[item.type], preset_name);

        break;

      default:
        break;
    }
  };

  const DropdownIndicator = () => {
    return (
      <>
        {value && value.length > 0 && (
          <div
            onMouseDown={(e) => {
              e.stopPropagation();
              e.preventDefault();
            }}
            onTouchEnd={(e) => {
              e.stopPropagation();
              e.preventDefault();
            }}
          >
            <span onClick={() => setOpen(true)}>
              <i className="bx bx-expand !text-textTitle cursor-pointer"></i>
            </span>
          </div>
        )}
      </>
    );
  };

  const components = {
    DropdownIndicator,
    IndicatorSeparator: null,
  };

  return (
    <>
      <CreatableSelect
        components={components}
        inputValue={inputValue[item.type]}
        isClearable={false}
        isMulti
        menuIsOpen={false}
        onChange={(newValue) => onChange(item, newValue, preset_name)}
        onInputChange={(newValue) => {
          setInputValue((prev) => ({ ...prev, [item.type]: newValue }));
        }}
        onKeyDown={handleKeyDown}
        placeholder={item.name}
        value={value || []}
        styles={customStyles}
        classNames={{
          control: () =>
            ` text-center text-sm px-2 focus:!outline-none hover:!outline-none dark:hover:!border-[#1E1E22] hover:!border-[#eaeaea] rounded shadow-md  py-[1px]`,
          input: () => "!text-[#5F5E5EE5] dark:!text-white",
          menu: () => "!text-textTitle !bg-text",
          placeholder: () => " text-[14px]  dark:text-white  text-[#5F5E5EE5]",
          option: (isFocused, isSelected) => {
            return `${
              isSelected ? "!bg-primary" : isFocused ? "bg-transparent" : ""
            } hover:!bg-borderBottom`;
          },
          multiValue: () =>
            "!bg-bg-gradient-primary-light dark:!bg-bg-gradient-primary-dark flex items-center p-1 !rounded hover:!bg-primary h-[24px]",
          multiValueLabel: () => "!text-text",
          multiValueRemove: () =>
            " !text-primary ml-1 !p-0 hover:!bg-text bg-text h-[16px] w-[16px] svgIcon-close !rounded-full flex items-center justify-center",
        }}
      />

      <CommonModal
        isOpen={open}
        setIsOpen={setOpen}
        closeModal={() => setOpen(false)}
        height={200}
      >
        <div
          className="w-[200px] md:w-[400px] sm:w-[300px] overflow-auto"
          style={{ padding: "16px 10px" }}
        >
          <div
            onClick={() => setOpen(false)}
            className="cursor-pointer flex justify-end"
          >
            <img
              src={
                theme === "dark"
                  ? "static/close-1.svg"
                  : "static/LightMode/close-2.svg"
              }
              width={12}
              alt="close"
            />
          </div>
          <div className="flex flex-wrap py-3">
            {value && value.length > 0 && value.map(renderSelectedOption)}
          </div>
        </div>
      </CommonModal>
    </>
  );
};

export default FiterMultiKeyword;
