export const overViewMockData = [
  {
    title: "Spy Search",
    icon: "static/DarkMode/SpySearchIcon-Overview-Dark.svg",
    iconLight: "static/LightMode/SpySearch-Icon-Overview-Light.svg",
    link: "spySearch",
  },
  {
    title: "Store Search",
    icon: "static/DarkMode/StoreSearchIcon-Overview-Dark.svg",
    iconLight: "static/LightMode/StoreSearch-Icon-Overview-Light.svg",
    link: "storeSearch",
  },
  {
    title: "Preiswecker",
    icon: "static/DarkMode/PreisweckerIcon-Overview-Dark.svg",
    iconLight: "static/LightMode/Preiswecker-Icon-Overview-Light.svg",
    link: "preiswecker",
  },
  {
    title: "Dealhub",
    icon: "static/DarkMode/DealHubIcon-Overview-Dark.svg",
    iconLight: "static/LightMode/DealHub-Icon-Overview-Light.svg",
    link: "dealHub",
  },
  {
    title: "A2A Flips",
    icon: "static/DarkMode/A2AIcon-Overview-Dark.svg",
    iconLight: "static/LightMode/A2a-Icon-Overview-Light.svg",
    link: "a2aFlips",
  },
];

export const selectCard = [
  {
    title: "Heutige Pings",
    link: "der letzten 24h",
    desc: 208,
  },
];
