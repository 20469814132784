import React from "react";
import Layout from "../layout/Layout";
import SubHeader from "../components/SubHeader";
import { useState, useEffect, useContext } from "react";
import { setAppElement } from "react-modal";
import CommonModal from "../components/CommonModal";
import { useDispatch, useSelector } from "react-redux";
import ProductContent from "../containers/ProductContent";
import FilterBearbeiten from "../containers/FilterBearbeiten";
import AsinsModal from "../containers/AsinsModal";
import { useCheckUserAuth } from "../utlis/useCheckUserAuth";
import NoProductsData from "../containers/NoProductsData";
import { fetchA2AProducts } from "../redux/slices/a2aProductSlice";
import {
  changeFilter,
  currentFilter,
  fetchFilter,
} from "../redux/slices/filterSlice";
import { findObject } from "../utlis/utlis";
import FiterMultiDropdown from "../containers/FilterMenu/FiterMultiDropdown";
import FilterDropdown from "../containers/FilterMenu/FilterDropdown";
import FiterMultiKeyword from "../containers/FilterMenu/FiterMultiKeyword";
import FilterKeyword from "../containers/FilterMenu/FilterKeyword";
import { ThemeContext } from "../context/themeContext";
import { useLocation } from "react-router-dom";
import SettingModal from "./SettingModal";
import ProductContentStore from "./ProductContentStore";
import FilterDropdownCheck from "./FilterMenu/FilterDropdownCheck";
import Skeleton from "react-loading-skeleton";

const ProductList = ({
  application,
  productType,
  header,
  subHeader,
  filterSubHeader,
  noProductbtnTitle,
  noProductDesc,
  priceAlertInfoName,
  addPriceAlertName,
  deletePriceAlertName,
  asinsModalHeader,
  asinsModalSubHeader,
  isAsinsModalHide,
  isStorePage,
  isTutorialModal,
  setTutorialModal,
  tutorialModal,
}) => {
  const dispatch = useDispatch();
  const location = useLocation();

  const productDetails = useSelector((state) => state.a2aProducts.a2aProducts);

  const authStatus = useSelector((state) => state.a2aProducts.authStatus);
  useCheckUserAuth(authStatus);

  const filterDetails = useSelector((state) => state.filter.filter);

  const currentFilterDetails = useSelector(
    (state) => state.filter.currentFilter
  );

  const changeFilterDetails = useSelector((state) => state.filter.changeFilter);

  const [isOpen, setIsOpen] = useState(false);
  const [asinsModal, setAsinsModal] = useState(false);
  const [productData, setProductData] = useState([]);
  const [offSet, setOffSet] = useState(0);
  const [isLoader, setIsLoader] = useState(false);
  const [newProductData, setNewProductData] = useState([]);
  const [filterValue, setFilterValue] = useState({});
  const [filterMenuDetails, setFilterMenuDetails] = useState({});
  const [inputValue, setInputValue] = useState([]);
  const [filterValueChanged, setFilterValueChanged] = useState(false);
  const [isAsinsLoader, setIsAsinsLoader] = useState(false);
  const [isProductFetch, setIsProductFetch] = useState(false);
  const [loading, setLoading] = useState(true);
  const [presetData, setPresetData] = useState([]);
  const [settingModal, setSettingModal] = useState(false);
  const { theme } = useContext(ThemeContext);

  const customStyles = {
    boxShadow: "0px 0px 5px 0px #0000001A",
    backgroundColor: theme === "dark" ? "#0f0f12" : "#ffffff",
  };

  useEffect(() => {
    setAppElement("#root");
    dispatch(fetchFilter(application));
    dispatch(currentFilter(application));
  }, []);

  useEffect(() => {
    const timer = setTimeout(() => {
      if (
        productDetails?.products &&
        filterDetails?.filter &&
        currentFilterDetails?.filter
      ) {
        setLoading(false);
      }
    }, 1000);
    return () => clearTimeout(timer);
  }, [productDetails, filterDetails, currentFilterDetails]);

  useEffect(() => {
    if (currentFilterDetails?.presets?.length > 0) {
      setPresetData(currentFilterDetails.presets);
    }
  }, [currentFilterDetails]);

  useEffect(() => {
    setIsProductFetch(false);
  }, [location.pathname]);

  useEffect(() => {
    setIsLoader(true);
    const payload = { productType, offSet };
    dispatch(fetchA2AProducts(payload));
    setIsProductFetch(true);
  }, [offSet]);

  useEffect(() => {
    if (
      isLoader &&
      isProductFetch &&
      productDetails &&
      productDetails.products
    ) {
      setProductData((prev) => [...prev, ...productDetails.products]);
      setNewProductData(productDetails.products);
      setIsLoader(false);
    }
  }, [productDetails]);

  const handleSettingModal = () => {
    setSettingModal(true);
  };

  const handleAsinsModal = () => {
    setAsinsModal(true);
  };

  const handleAsinsClose = () => {
    setAsinsModal(false);
  };

  const handleAmazonFlipsClose = () => {
    setIsOpen(false);
    setSettingModal(false);
    setFilterValueChanged(false);
    filterValueChanged && window.location.reload();
  };

  const handleAmazonFlips = () => {
    setIsOpen(true);
    setIsAsinsLoader(true);
  };

  useEffect(() => {
    if (
      filterDetails &&
      filterDetails.filter &&
      filterDetails.filter.filter &&
      Array.isArray(filterDetails.filter.filter) &&
      currentFilterDetails &&
      currentFilterDetails.filter &&
      Array.isArray(currentFilterDetails.filter)
    ) {
      setFilterMenuDetails(filterDetails.filter);

      const currentFilterData = currentFilterDetails.filter.reduce(
        (acc, item) => {
          return item ? { ...acc, ...item } : acc;
        },
        {}
      );

      const output = findObject(filterDetails.filter.filter, currentFilterData);

      let outputPresets = [];
      let presetNames = [];

      if (Array.isArray(currentFilterDetails.presets)) {
        const currentFilterPresetsData = currentFilterDetails.presets.map(
          (item) => {
            return item.reduce((acc, item) => {
              return item ? { ...acc, ...item } : acc;
            }, {});
          }
        );

        outputPresets = currentFilterPresetsData.map((item) => {
          return findObject(filterDetails.filter.filter, item);
        });

        presetNames = currentFilterDetails.presets.map((preset) => {
          if (Array.isArray(preset)) {
            const foundItem = preset.find((item) => item.preset_name);
            return foundItem ? foundItem.preset_name : undefined;
          }
          return undefined;
        });
      }

      const result = {};

      presetNames.forEach((presetName, index) => {
        if (presetName !== undefined) {
          result[presetName] = outputPresets[index];
        }
      });

      setFilterValue({
        filter: output,
        presets: result,
      });
      setIsAsinsLoader(false);
    }
  }, [filterDetails, currentFilterDetails]);

  useEffect(() => {
    if (
      !isOpen &&
      !settingModal &&
      changeFilterDetails &&
      changeFilterDetails.message
    ) {
      window.location.reload();
    }
  }, [changeFilterDetails]);

  useEffect(() => {
    if (changeFilterDetails && changeFilterDetails.message) {
      setFilterValueChanged(true);
    }
  }, [changeFilterDetails]);

  const handleChangeDropdown = (item, selectedItem, preset_name) => {
    let newValue;
    const isPreset = preset_name !== undefined;
    const targetObject = isPreset ? "presets" : "filter";

    setFilterValue((prev) => {
      const newState = { ...prev };
      const currentValue = isPreset
        ? prev[targetObject][preset_name][item.type]
        : prev[targetObject][item.type];

      newValue =
        currentValue && currentValue.value === selectedItem.value
          ? ""
          : selectedItem;

      if (isPreset) {
        newState[targetObject][preset_name][item.type] = newValue;
      } else {
        newState[targetObject][item.type] = newValue;
      }

      return newState;
    });

    let filterData = {
      application: application,
      type: item.type,
      attribute: selectedItem.value,
    };

    preset_name && (filterData = { ...filterData, preset_name });

    if (newValue === "") {
      filterData.attribute = "";
    }
    dispatch(changeFilter(filterData));
  };
  const handleChangeMultiDropdown = (item, selectedItem, preset_name) => {
    let filterData = {};

    const isPreset = preset_name !== undefined;
    const targetObject = isPreset ? "presets" : "filter";

    const previousFilterValue = isPreset
      ? filterValue["presets"][preset_name] &&
        filterValue["presets"][preset_name][item.type]
      : filterValue["filter"][item.type];

    setFilterValue((prev) => {
      const newState = { ...prev };
      const newValue = selectedItem;

      if (isPreset) {
        newState[targetObject][preset_name][item.type] = newValue;
      } else {
        newState[targetObject][item.type] = newValue;
      }

      return newState;
    });

    if (selectedItem?.length < previousFilterValue?.length) {
      const deselectedItem = previousFilterValue.find(
        (prev) => !selectedItem.some((sel) => sel.value === prev.value)
      );

      if (deselectedItem) {
        filterData = {
          application: application,
          type: item.type,
          attribute: [deselectedItem.value],
        };
      }
    } else {
      const latestItem = selectedItem[selectedItem?.length - 1];

      filterData = {
        application: application,
        type: item.type,
        attribute: latestItem ? [latestItem.value] : [],
      };
    }

    if (preset_name) {
      filterData = {
        ...filterData,
        preset_name: preset_name,
      };
    }

    dispatch(changeFilter(filterData));
  };

  const handleChangeKeyword = (e, preset_name) => {
    const value = e.target.value;
    const name = e.target.name;

    const isPreset = preset_name !== undefined;
    const targetObject = isPreset ? "presets" : "filter";

    const oldValue = isPreset
      ? filterValue[targetObject][preset_name]?.[name]?.value || null
      : filterValue[targetObject][name]?.value || null;

    if (!isNaN(value)) {
      setFilterValue((prev) => {
        const newState = { ...prev };
        const newValue = { value: value, oldValue: oldValue };

        if (isPreset) {
          newState[targetObject][preset_name][name] = newValue;
        } else {
          newState[targetObject][name] = newValue;
        }

        return newState;
      });
    }
  };

  const handleBlur = (e, item, preset_name) => {
    const value = e.target.value;
    const name = e.target.name;

    const isPreset = preset_name !== undefined;
    const targetObject = isPreset ? "presets" : "filter";

    const oldValue = isPreset
      ? filterValue[targetObject][preset_name]?.[name]?.oldValue || null
      : filterValue[targetObject][name]?.oldValue || null;

    if (value) {
      const num = parseFloat(value);
      let newValueUpdated = value;

      if (!isNaN(value)) {
        newValueUpdated = num.toFixed(2) + item.unit;

        setFilterValue((prev) => {
          const newState = { ...prev };
          const newValue = { value: newValueUpdated, oldValue: oldValue };

          if (isPreset) {
            newState[targetObject][preset_name][name] = newValue;
          } else {
            newState[targetObject][name] = newValue;
          }

          return newState;
        });

        if (Number(value) !== oldValue) {
          let filterData = {
            application: application,
            type: name,
            attribute: value,
          };

          preset_name &&
            (filterData = {
              ...filterData,
              preset_name: preset_name,
            });

          dispatch(changeFilter(filterData));
        }
      }
    } else {
      if (!value && oldValue) {
        let filterData = {
          application: application,
          type: name,
          attribute: oldValue,
        };

        preset_name &&
          (filterData = {
            ...filterData,
            preset_name: preset_name,
          });

        dispatch(changeFilter(filterData));
      }
    }
  };

  const handleFocus = (e, preset_name) => {
    const value = e.target.value;
    const name = e.target.name;

    const isPreset = preset_name !== undefined;
    const targetObject = isPreset ? "presets" : "filter";

    if (value === "") return;
    const newValueUpdated = parseFloat(value.replace(/%$/, ""));

    setFilterValue((prev) => {
      const newState = { ...prev };
      const newValue = { value: newValueUpdated, oldValue: newValueUpdated };

      if (isPreset) {
        newState[targetObject][preset_name][name] = newValue;
      } else {
        newState[targetObject][name] = newValue;
      }

      return newState;
    });
  };

  const handleEnterItem = (item, value, preset_name) => {
    const isPreset = preset_name !== undefined;
    const targetObject = isPreset ? "presets" : "filter";

    setFilterValue((prev) => {
      const newState = { ...prev };

      if (isPreset) {
        const existingValues =
          newState[targetObject][preset_name][item.type] || [];
        const newValue = { value: value, label: value };
        newState[targetObject][preset_name][item.type] = [
          ...existingValues,
          newValue,
        ];
      } else {
        const existingValues = newState[targetObject][item.type] || [];
        const newValue = { value: value, label: value };
        newState[targetObject][item.type] = [...existingValues, newValue];
      }

      return newState;
    });

    let filterData = {
      application: application,
      type: item.type,
      attribute: value,
    };

    preset_name &&
      (filterData = {
        ...filterData,
        preset_name: preset_name,
      });

    dispatch(changeFilter(filterData));
  };

  const handleChangeMultiKeyword = (item, value, preset_name) => {
    let filterData = {};

    const isPreset = preset_name !== undefined;
    const targetObject = isPreset ? "presets" : "filter";

    const filterTypeValue = isPreset
      ? filterValue[targetObject][preset_name]?.[item.type] || []
      : filterValue[targetObject][item.type] || [];

    setFilterValue((prev) => {
      const newState = { ...prev };

      if (isPreset) {
        newState[targetObject][preset_name][item.type] = value;
      } else {
        newState[targetObject][item.type] = value;
      }

      return newState;
    });

    if (value.length === 0 || value.length < filterTypeValue.length) {
      const removedValue = filterTypeValue.filter((x) => value.indexOf(x) < 0);
      filterData = {
        application: application,
        type: item.type,
        attribute: removedValue.map((x) => x.value),
      };

      preset_name &&
        (filterData = {
          ...filterData,
          preset_name: preset_name,
        });

      dispatch(changeFilter(filterData));
    }
  };

  const handleDropdownCheckbox = (item, checkboxValue, value, preset_name) => {
    const isPreset = preset_name !== undefined;
    const targetObject = isPreset ? "presets" : "filter";

    setFilterValue((prev) => {
      const newState = { ...prev };

      if (isPreset) {
        newState[targetObject][preset_name][item.type] = checkboxValue;
      } else {
        newState[targetObject][item.type] = checkboxValue;
      }

      return newState;
    });

    let filterData = {
      application: application,
      type: item.type,
      attribute: value,
    };

    preset_name &&
      (filterData = {
        ...filterData,
        preset_name: preset_name,
      });

    dispatch(changeFilter(filterData));
  };

  const renderDropdownContent = (item, i, preset_name) => {
    if (item.filter_bar) {
      if (item.input_type === "dropdown") {
        if (item.multiple_selection) {
          return (
            <FiterMultiDropdown
              key={i}
              item={item}
              filterValue={filterValue}
              onChange={handleChangeMultiDropdown}
            />
          );
        } else {
          const value = preset_name
            ? filterValue["presets"]?.[preset_name]?.[item.type] &&
              filterValue["presets"]?.[preset_name]?.[item.type]
            : filterValue["filter"]?.[item.type];
          return (
            <FilterDropdown
              key={i}
              options={item.values.map((option) => ({
                label: option.value,
                value: option.request_value,
              }))}
              placeholder={item.name}
              onChange={(value) => handleChangeDropdown(item, value)}
              value={value}
              width={"110px"}
            />
          );
        }
      } else if (item.input_type === "dropdown_checkbox") {
        return (
          <FilterDropdownCheck
            item={item}
            onChange={handleDropdownCheckbox}
            filterValue={filterValue}
          />
        );
      } else {
        if (item.multiple_selection) {
          return (
            <FiterMultiKeyword
              key={i}
              item={item}
              inputValue={inputValue}
              setInputValue={setInputValue}
              handleEnterItem={handleEnterItem}
              filterValue={filterValue}
              setFilterValue={setFilterValue}
              onChange={handleChangeMultiKeyword}
            />
          );
        } else {
          return (
            <FilterKeyword
              key={i}
              item={item}
              onChange={handleChangeKeyword}
              handleBlur={(e) => handleBlur(e, item)}
              handleFocus={handleFocus}
              filterValue={filterValue}
              setFilterValue={setFilterValue}
            />
          );
        }
      }
    }
  };

  return (
    <>
      <Layout>
        <section className="home-section bg-background no-scrollbar">
          <div className="text border-b-[1px] border-borderBottom">
            <SubHeader date>
              <p className="title text-textTitle font-semibold tracking-wide text-[20px]">
                {header}
                <span
                  className="bg-gradient-to-br from-primary to-main bg-clip-text text-transparent ml-1 font-semibold 
                    tracking-wide">
                  {subHeader}
                </span>
              </p>
            </SubHeader>
          </div>

          <div className="flex flex-wrap gap-6 pb-2 pt-2 px-4 md:px-10 product-drp-header">
            {loading ? (
              Array.from({
                length: 4,
              }).map((_, index) => (
                <Skeleton
                  key={index}
                  height={40}
                  width={120}
                  baseColor={theme === "dark" ? "#0e0e12" : "#f2f2f2"}
                  highlightColor={theme === "dark" ? "#1a1a1a" : "#ffffff"}
                />
              ))
            ) : (
              <>
                {filterMenuDetails["filter"] &&
                  filterMenuDetails["filter"].map((item, index) =>
                    renderDropdownContent(item, index)
                  )}

                {filterMenuDetails.all_filters_box && (
                  <div className="dropdown">
                    <button
                      className="dropbtn dark:!bg-bg-filter-bg-color-dark text-sm font-medium capitalizew-[110px] flex  px-6 
                  "
                      style={customStyles}
                      onClick={handleAmazonFlips}>
                      <span
                        className="dark:!bg-text-filter-dark !bg-white  "
                        style={{
                          backgroundClip: "text",
                          color: theme === "dark" ? "transparent" : "#5F5E5EE5",
                        }}>
                        Alle Filter &nbsp;{" "}
                      </span>{" "}
                      <i className="bx bx-filter text-[16px] text-textPrimary"></i>
                    </button>
                  </div>
                )}

                {isStorePage && (
                  <div className="dropdown">
                    <button
                      className="dropbtn hover:border-primary  text-primary cursor-pointer text-sm border-[1px] 
                  border-primary font-medium flex shadow capitalize px-6 dark:bg-custom-gradient-primary-dark 
                  bg-custom-gradient-primary-light "
                      onClick={handleSettingModal}>
                      Suppliers &nbsp;
                      <i className="bx bx-plus text-[18px]"></i>
                    </button>
                  </div>
                )}

                {!isAsinsModalHide && (
                  <div className="dropdown">
                    <button
                      className="dropbtn hover:border-primary  dark:bg-custom-gradient-primary-dark 
                  bg-custom-gradient-primary-light  text-primary cursor-pointer text-sm border-[1px] border-primary font-medium flex pr-2 shadow capitalize"
                      onClick={handleAsinsModal}>
                      Asins bearbeiten &nbsp;
                      <i className="bx bx-plus text-[18px]"></i>
                    </button>
                  </div>
                )}
              </>
            )}
          </div>

          {productData.length > 0 || loading ? (
            <div className="px-10">
              <section className="gap-4">
                {isStorePage ? (
                  <ProductContentStore
                    productData={productData}
                    setOffSet={setOffSet}
                    newProductData={newProductData}
                    isLoader={isLoader}
                    initialLoading={loading}
                  />
                ) : (
                  <ProductContent
                    productData={productData}
                    setOffSet={setOffSet}
                    newProductData={newProductData}
                    isLoader={isLoader}
                    initialLoading={loading}
                  />
                )}
              </section>
            </div>
          ) : (
            <NoProductsData
              btnTitle={noProductbtnTitle}
              description={noProductDesc}
            />
          )}
        </section>

        <CommonModal
          isOpen={isOpen}
          setIsOpen={setIsOpen}
          closeModal={handleAmazonFlipsClose}>
          <FilterBearbeiten
            closeModal={handleAmazonFlipsClose}
            filterMenuDetails={filterMenuDetails}
            handleChangeDropdown={handleChangeDropdown}
            filterValue={filterValue}
            handleChangeKeyword={handleChangeKeyword}
            handleBlur={handleBlur}
            handleFocus={handleFocus}
            inputValue={inputValue}
            setInputValue={setInputValue}
            handleChangeMultiDropdown={handleChangeMultiDropdown}
            handleEnterItem={handleEnterItem}
            handleChangeMultiKeyword={handleChangeMultiKeyword}
            setFilterValue={setFilterValue}
            isAsinsLoader={isAsinsLoader}
            application={application}
            filterSubHeader={filterSubHeader}
            presetData={presetData}
            handleDropdownCheckbox={handleDropdownCheckbox}
            filterModal={true}
            setIsOpen={setIsOpen}
          />
        </CommonModal>

        <CommonModal
          isOpen={asinsModal}
          setIsOpen={setAsinsModal}
          closeModal={handleAsinsClose}>
          <AsinsModal
            closeModal={handleAsinsClose}
            priceAlertInfoName={priceAlertInfoName}
            addPriceAlertName={addPriceAlertName}
            deletePriceAlertName={deletePriceAlertName}
            asinsModalHeader={asinsModalHeader}
            asinsModalSubHeader={asinsModalSubHeader}
            isTutorialModal={isTutorialModal}
            setTutorialModal={setTutorialModal}
            tutorialModal={tutorialModal}
          />
        </CommonModal>

        <CommonModal
          isOpen={settingModal}
          setIsOpen={setSettingModal}
          closeModal={handleAmazonFlipsClose}>
          <SettingModal
            closeModal={handleAmazonFlipsClose}
            priceAlertInfoName={priceAlertInfoName}
            addPriceAlertName={addPriceAlertName}
            deletePriceAlertName={deletePriceAlertName}
            asinsModalHeader={asinsModalHeader}
            asinsModalSubHeader={asinsModalSubHeader}
            filterMenuDetails={filterMenuDetails}
            application={application}
            setFilterValue={setFilterValue}
            handleDropdownCheckbox={handleDropdownCheckbox}
            filterValue={filterValue}
          />
        </CommonModal>
      </Layout>
    </>
  );
};
export default ProductList;
