import React, { useContext } from "react";
import { ThemeContext } from "../context/themeContext";
import FilterDropdownCheck from "./FilterMenu/FilterDropdownCheck";
import SubHeader from "../components/SubHeader";

const SettingModal = ({
  closeModal,
  asinsModalSubHeader,
  filterMenuDetails,
  handleDropdownCheckbox,
  filterValue,
}) => {
  const { theme } = useContext(ThemeContext);

  const supplierFilter = filterMenuDetails.filter.find(
    (item) => item.input_type === "dropdown_checkbox"
  );

  const handleSave = () => {
    closeModal();
  };

  return (
    <div className="relative">
      <div className="bg-background p-12 rounded-lg w-[400px] md:w-[700px] lg:w-[800px]  !h-[575px] md:h-auto">
        <div className="bg-background mb-4 flex justify-between items-center">
          <div>
            <SubHeader>
              <p className="title  text-3xl font-semibold bg-gradient-to-r from-primary to-main text-transparent bg-clip-text">
                Store
                <span className="text-textTitle ml-1"> Search</span>
              </p>
              <p className="subtitle2 mt-1">{asinsModalSubHeader}</p>
            </SubHeader>
          </div>
          <div
            onClick={closeModal}
            className="bg-secondary w-[30px] h-[30px] flex justify-center items-center cursor-pointer rounded absolute top-4 right-3"
          >
            <img
              src={
                theme === "dark"
                  ? "static/close-1.svg"
                  : "static/LightMode/close-2.svg"
              }
              width={15}
              className="float-right"
              alt="close"
            />
          </div>
        </div>

        <div className="flex flex-col gap-y-1">
          <div className="gap-x-10 gap-y-4">
            <div className="text-left gap-6">
              <div className="flex items-start flex-col">
                <div>
                  <p className="subtitle !text-[11px]">Stores</p>
                </div>
                <div className="w-full">
                  <FilterDropdownCheck
                    item={supplierFilter}
                    onChange={handleDropdownCheckbox}
                    filterValue={filterValue}
                  />
                </div>
              </div>
            </div>
          </div>

          <div className="flex gap-4 mt-6">
            <button
              className="p_detail px-2 pt-2 pb-2 text-xs font-semibold text-text border-none capitalize bg-gradient-to-br from-primary to-main rounded text-center w-28 tracking-widest"
              onClick={handleSave}
            >
              Speichern
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SettingModal;
