import React, { useEffect, useState } from "react";
import { useContext } from "react";
import { ThemeContext } from "../context/themeContext";
import SubHeader from "../components/SubHeader";
import FiterMultiDropdown from "./FilterMenu/FiterMultiDropdown";
import { currentFilter } from "../redux/slices/filterSlice";
import { useDispatch } from "react-redux";
import FilterDropdown from "./FilterMenu/FilterDropdown";
import FiterMultiKeyword from "./FilterMenu/FiterMultiKeyword";
import FilterKeyword from "./FilterMenu/FilterKeyword";
import CommonModal from "../components/CommonModal";
import PresetModal from "./PresetModal";
import FilterDropdownCheck from "./FilterMenu/FilterDropdownCheck";
import Tooltip from "../components/Tooltip";

const FilterBearbeiten = ({
  closeModal,
  filterMenuDetails,
  handleChangeDropdown,
  filterValue,
  handleChangeKeyword,
  handleBlur,
  handleFocus,
  inputValue,
  setInputValue,
  handleChangeMultiDropdown,
  handleEnterItem,
  setFilterValue,
  isAsinsLoader,
  application,
  filterSubHeader,
  handleChangeMultiKeyword,
  presetData,
  handleDropdownCheckbox,
  filterModal,
  setIsOpen,
}) => {
  const { theme } = useContext(ThemeContext);
  const dispatch = useDispatch();

  const { setFilterType } = useContext(ThemeContext);

  const [isOpenPresets, setIsOpenPresets] = useState(false);

  useEffect(() => {
    dispatch(currentFilter(application));
  }, []);

  const renderDropdownContent = (item, preset_name) => {
    if (item.input_type === "dropdown") {
      if (item.multiple_selection) {
        return (
          <FiterMultiDropdown
            item={item}
            filterValue={filterValue}
            onChange={handleChangeMultiDropdown}
            preset_name={preset_name}
            filterModal={filterModal}
          />
        );
      } else {
        const value = preset_name
          ? filterValue?.["presets"]?.[preset_name] &&
            filterValue?.["presets"]?.[preset_name]?.[item.type]
          : filterValue?.["filter"]?.[item.type];

        return (
          <FilterDropdown
            options={item.values.map((option) => ({
              label: option.value,
              value: option.request_value,
            }))}
            placeholder={item.name}
            onChange={(value) => handleChangeDropdown(item, value, preset_name)}
            value={value}
            filterModal={filterModal}
          />
        );
      }
    } else if (item.input_type === "dropdown_checkbox") {
      return (
        <FilterDropdownCheck
          item={item}
          onChange={handleDropdownCheckbox}
          preset_name={preset_name}
          filterValue={filterValue}
          filterModal={filterModal}
        />
      );
    } else {
      if (item.multiple_selection) {
        return (
          <FiterMultiKeyword
            item={item}
            inputValue={inputValue}
            setInputValue={setInputValue}
            handleEnterItem={handleEnterItem}
            filterValue={filterValue}
            setFilterValue={setFilterValue}
            onChange={handleChangeMultiKeyword}
            preset_name={preset_name}
            filterModal={filterModal}
          />
        );
      } else {
        return (
          <FilterKeyword
            item={item}
            onChange={handleChangeKeyword}
            handleBlur={(e) => handleBlur(e, item, preset_name)}
            handleFocus={(e) => handleFocus(e, preset_name)}
            filterValue={filterValue}
            setFilterValue={setFilterValue}
            preset_name={preset_name}
            filterModal={filterModal}
          />
        );
      }
    }
  };

  const handleSave = () => {
    closeModal();
  };

  const hanldePresets = () => {
    setIsOpenPresets(true);
    setFilterType("presets");
  };

  const hanldePresetsClose = () => {
    setIsOpenPresets(false);
    setFilterType("filter");
  };

  const presetNames = presetData.map((preset) => {
    return preset.find((item) => item.preset_name).preset_name;
  });

  return (
    <>
      <div
        className="bg-background p-12 py-8  rounded-lg w-[360px] sm:w-[600px] md:w-[700px] md:h-[500px] lg:w-[700px] lg:h-[500px] xl:w-[800px]  relative
      overflow-auto xl:!h-[750px]">
        <div className="bg-background mb-4 flex justify-between items-start">
          <div>
            <SubHeader>
              <p className="title  text-3xl font-semibold bg-gradient-to-r from-primary to-main text-transparent bg-clip-text">
                Filter
                <span className="text-textTitle ml-1"> bearbeiten</span>
              </p>
              <p className="subtitle2 mt-1">{filterSubHeader}</p>
            </SubHeader>
          </div>
          <div
            onClick={closeModal}
            className="bg-secondary w-[30px] h-[30px] flex justify-center items-center cursor-pointer rounded absolute top-4 right-3">
            <img
              src={
                theme === "dark"
                  ? "static/close-1.svg"
                  : "static/LightMode/close-2.svg"
              }
              width={15}
              className="float-right"
              alt="close"
            />
          </div>
        </div>
        <div className="flex justify-center w-full  2xl:h-[500px] xl:h-[480px] lg:h-[300px] md:h-[300px]  h-[300px] overflow-y-auto overflow-x-hidden">
          <div className="">
            <div className="grid w-fit gap-x-20 gap-y-6 md:grid-cols-2 xl:grid-cols-2 sm:grid-cols-1">
              {filterMenuDetails &&
                filterMenuDetails.filter.map((item, index) => {
                  return (
                    <div key={index} className="text-left w-60">
                      <div className="flex items-center">
                        <div>
                          <p className="subtitle !text-[12px] mr-1 flex items-center gap-1">
                            {item.name}
                            {item.tooltip && (
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                fill="none"
                                viewBox="0 0 24 24"
                                strokeWidth={1.5}
                                stroke="currentColor"
                                className="size-3.5 tooltip-on-hover">
                                <path
                                  strokeLinecap="round"
                                  strokeLinejoin="round"
                                  d="m11.25 11.25.041-.02a.75.75 0 0 1 1.063.852l-.708 2.836a.75.75 0 0 0 1.063.853l.041-.021M21 12a9 9 0 1 1-18 0 9 9 0 0 1 18 0Zm-9-3.75h.008v.008H12V8.25Z"
                                />
                              </svg>
                            )}
                            <Tooltip label={item.tooltip} />
                          </p>
                        </div>
                      </div>
                      {renderDropdownContent(item)}
                    </div>
                  );
                })}
            </div>
          </div>
        </div>
        <div className="flex justify-center w-[200px] ml-[35px] sm:ml-[90px] md:ml-[53px] lg:ml-24 mt-4 gap-3">
          <div>
            <p className="subtitle !text-[12px] ">Filter speichern</p>
            <button
              className="p_detail text-xs font-semibold p-3 text-text bg-gradient-to-br from-primary to-main rounded text-center 
              w-32 border-none capitalize "
              onClick={handleSave}>
              speichern
            </button>
          </div>

          {filterMenuDetails.presets_active && (
            <div>
              <p className="subtitle !text-[12px] ">Presets bearbeiten</p>
              <button
                className="p_detail text-xs font-semibold p-3  bg-secondary text-primary rounded text-center w-32 
                border-none capitalize"
                onClick={hanldePresets}>
                Presets
              </button>
            </div>
          )}
        </div>
        {isAsinsLoader && (
          <div className="spinner">
            <i className="animate-spin bx bx-loader-alt text-4xl text-primary"></i>
          </div>
        )}
        <CommonModal
          isOpen={isOpenPresets}
          setIsOpen={setIsOpenPresets}
          closeModal={hanldePresetsClose}>
          <PresetModal
            closeModal={hanldePresetsClose}
            filterSubHeader={
              <>
                <p>Steuere gezielt welche Produkte deinen Vorstellungen </p>
                <p>entsprechen und erhalte nur die besten Treffer</p>
              </>
            }
            renderDropdownContent={renderDropdownContent}
            filterMenuDetails={filterMenuDetails}
            presetNames={presetNames}
            setIsOpen={setIsOpen}
          />
        </CommonModal>
      </div>
    </>
  );
};
export default FilterBearbeiten;
