import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import {
  changeStoreFilterAPI,
  getStoreCurrentFilterAPI,
  getStoreFilterAPI,
  getStoreProductsAPI,
} from "../../lib/api/api";


export const fetchStoreProducts = createAsyncThunk(
  "products/getStoreProductsAPI",
  async (offSet, { dispatch }) => {
    const response = await getStoreProductsAPI(offSet);
    const status = response?.status;

    if (status === "unauthorized") {
      dispatch(setAuthStatus(status));
    }

    return response;
  }
);

export const fetchStoreFilter = createAsyncThunk(
  "filter/getStoreFilterAPI",
  async (application, { dispatch }) => {
    const response = await getStoreFilterAPI(application);
    const status = response?.status;
    if (status === "unauthorized") {
      dispatch(setAuthStatus(status));
    }
    return response;
  }
);

export const currentStoreFilter = createAsyncThunk(
  "currentFilter/getStoreCurrentFilterAPI",
  async (application, { dispatch }) => {
    const response = await getStoreCurrentFilterAPI(application);
    const status = response?.status;

    if (status === "unauthorized") {
      dispatch(setAuthStatus(status));
    }
    return response;
  }
);

export const changeStoreFilter = createAsyncThunk(
  "changeFilter/changeStoreFilterAPI",
  async (filterData, { dispatch }) => {
    const { application, type, attribute } = filterData;
    const response = await changeStoreFilterAPI(application, type, attribute);
    const status = response?.status;

    if (status === "unauthorized") {
      dispatch(setAuthStatus(status));
    }
    return response;
  }
);

const filterStoreSlice = createSlice({
  name: "filterStore",
  initialState: {
    filter: null,
    storeProduct : null,
    status: "idle",
    error: null,
    currentFilter: null,
    currentFilterStatus: "idle",
    currentFilterError: null,
    changeFilter: null,
    changeFilterStatus: "idle",
    changeFilterError: null,
    authStatus: null,
  },
  reducers: {
    setAuthStatus: (state, action) => {
      state.authStatus = action.payload;
    },
    resetStoreProducts: (state) => {
      state.storeProduct = null;
      state.status = "idle";
      state.error = null;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchStoreProducts.pending, (state) => {
        state.status = "loading";
      })
      .addCase(fetchStoreProducts.fulfilled, (state, action) => {
        state.status = "succeeded";
        state.products = action.payload;
      })
      .addCase(fetchStoreProducts.rejected, (state, action) => {
        state.status = "failed";
        state.error = action.error.message;
      })
      .addCase(fetchStoreFilter.pending, (state) => {
        state.status = "loading";
      })
      .addCase(fetchStoreFilter.fulfilled, (state, action) => {
        state.status = "succeeded";
        state.filter = action.payload;
      })
      .addCase(fetchStoreFilter.rejected, (state, action) => {
        state.status = "failed";
        state.error = action.error.message;
      })
      .addCase(currentStoreFilter.pending, (state) => {
        state.currentFilterStatus = "loading";
      })
      .addCase(currentStoreFilter.fulfilled, (state, action) => {
        state.currentFilterStatus = "succeeded";
        state.currentFilter = action.payload;
      })
      .addCase(currentStoreFilter.rejected, (state, action) => {
        state.currentFilterStatus = "failed";
        state.currentFilterError = action.error.message;
      })
      .addCase(changeStoreFilter.pending, (state) => {
        state.changeFilterStatus = "loading";
      })
      .addCase(changeStoreFilter.fulfilled, (state, action) => {
        state.changeFilterStatus = "succeeded";
        state.changeFilter = action.payload;
      })
      .addCase(changeStoreFilter.rejected, (state, action) => {
        state.changeFilterStatus = "failed";
        state.changeFilterError = action.error.message;
      });
  },
});

export const { setAuthStatus, resetStoreProducts } = filterStoreSlice.actions;
export default filterStoreSlice.reducer;
