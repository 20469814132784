import React, { useContext } from "react";
import { useState } from "react";
import { setAppElement } from "react-modal";
import { useEffect } from "react";
import Layout from "../layout/Layout";
import SpyProductCard from "../containers/SpyProductCard";
import ProductRightCard from "../containers/ProductRightCard";
import SubHeader from "../components/SubHeader";
import CommonModal from "../components/CommonModal";
import { fetchProducts } from "../redux/slices/productsSlice";
import { useDispatch, useSelector } from "react-redux";
import NoSellersEmpty from "../containers/NoSellersEmpty";
import SellerTracking from "../containers/SellerTracking";
import { useCheckUserAuth } from "../utlis/useCheckUserAuth";
import {
  changeFilter,
  currentFilter,
  fetchFilter,
} from "../redux/slices/filterSlice";
import { findObject } from "../utlis/utlis";
import FilterDropdown from "../containers/FilterMenu/FilterDropdown";
import FiterMultiDropdown from "../containers/FilterMenu/FiterMultiDropdown";
import FiterMultiKeyword from "../containers/FilterMenu/FiterMultiKeyword";
import FilterKeyword from "../containers/FilterMenu/FilterKeyword";
import Skeleton from "react-loading-skeleton";
import { ThemeContext } from "../context/themeContext";

const SpySearch = () => {
  const application = "spy";
  const dispatch = useDispatch();
  const productDetails = useSelector((state) => state.products.products);
  const filterDetails = useSelector((state) => state.filter.filter);
  const changeFilterDetails = useSelector((state) => state.filter.changeFilter);
  const currentFilterDetails = useSelector(
    (state) => state.filter.currentFilter
  );
  const authStatus = useSelector((state) => state.products.authStatus);
  useCheckUserAuth(authStatus);
  const [isOpen, setIsOpen] = useState(false);
  const [productData, setProductData] = useState([]);
  const [selectedProduct, setSelectedProduct] = useState({});
  const [offSet, setOffSet] = useState(0);
  const [isSelected, setIsSelected] = useState(true);
  const [isLoader, setIsLoader] = useState(false);
  const [newProductData, setNewProductData] = useState([]);
  const [filterValue, setFilterValue] = useState({});
  const [filterMenuDetails, setFilterMenuDetails] = useState({});
  const [inputValue, setInputValue] = useState("");
  const [loading, setLoading] = useState(false);
  useEffect(() => {
    setIsLoader(true);
    dispatch(fetchProducts(offSet));
  }, [offSet]);

  useEffect(() => {
    if (productDetails && productDetails.products) {
      if (productDetails.products.length > 0 && isSelected) {
        setSelectedProduct(productDetails.products[0]);
        setIsSelected(false);
      }
      setProductData((prev) => [...prev, ...productDetails.products]);
      setNewProductData(productDetails.products);
      setIsLoader(false);
    }
  }, [productDetails]);

  const closeModal = () => {
    setIsOpen(false);
  };

  useEffect(() => {
    setAppElement("#root");
    dispatch(fetchFilter(application));
    dispatch(currentFilter(application));
    setLoading(true);
  }, []);

  useEffect(() => {
    const timer = setTimeout(() => {
      if (
        productDetails?.products &&
        filterDetails?.filter &&
        currentFilterDetails?.filter
      ) {
        setLoading(false);
      }
    }, 1000);
    return () => clearTimeout(timer);
  }, [productDetails, filterDetails, currentFilterDetails]);

  useEffect(() => {
    if (
      filterDetails &&
      filterDetails.filter &&
      filterDetails.filter.filter &&
      Array.isArray(filterDetails.filter.filter) &&
      currentFilterDetails &&
      currentFilterDetails.filter &&
      Array.isArray(currentFilterDetails.filter)
    ) {
      setFilterMenuDetails(filterDetails.filter);

      if (currentFilterDetails.filter.length > 0) {
        const currentFilterData = currentFilterDetails.filter.reduce(
          (acc, item) => {
            // Ensure 'item' is defined before spreading it
            return item ? { ...acc, ...item } : acc;
          },
          {}
        );

        const output = findObject(
          filterDetails.filter.filter,
          currentFilterData
        );
        setFilterValue(output);
      }
    }
  }, [filterDetails, currentFilterDetails]);

  useEffect(() => {
    if (changeFilterDetails && changeFilterDetails.message) {
      window.location.reload();
    }
  }, [changeFilterDetails]);

  const handleSpySearch = () => {
    setIsOpen(true);
  };

  const handleChangeDropdown = (item, selectedItem) => {
    setFilterValue((prev) => {
      const currentValue = prev[item.type];

      const newValue =
        currentValue && currentValue.value === selectedItem.value
          ? null
          : selectedItem;

      return {
        ...prev,
        [item.type]: newValue,
      };
    });

    const filterData = {
      application: application,
      type: item.type,
      attribute: selectedItem.value,
    };

    if (
      selectedItem.value ===
      (filterValue[item.type] && filterValue[item.type].value)
    ) {
      filterData.attribute = "";
    }

    dispatch(changeFilter(filterData));
  };

  const handleChangeMultiDropdown = (item, selectedItem, preset_name) => {
    let filterData = {};
    const isPreset = preset_name !== undefined;
    const targetObject = isPreset ? "presets" : "filter";
    const previousFilterTypeValue = filterValue[item.type];

    setFilterValue((prev) => {
      const newState = { ...prev };
      const newValue = selectedItem;

      if (!newState[targetObject]) {
        newState[targetObject] = {};
      }

      if (isPreset) {
        if (!newState[targetObject][preset_name]) {
          newState[targetObject][preset_name] = {};
        }
        newState[targetObject][preset_name][item?.type] = newValue;
      } else {
        newState[targetObject][item?.type] = newValue;
      }

      return newState;
    });

    const deselectedItems = previousFilterTypeValue
      ? previousFilterTypeValue.filter((x) => selectedItem.indexOf(x) === -1)
      : [];

    if (deselectedItems.length > 0) {
      filterData = {
        application: application,
        type: item.type,
        attribute: deselectedItems.map((d) => d.value),
      };
    } else if (selectedItem.length) {
      const latestItem = selectedItem[selectedItem.length - 1];
      filterData = {
        application: application,
        type: item.type,
        attribute: [latestItem?.value],
      };
    }

    if (preset_name) {
      filterData = {
        ...filterData,
        preset_name: preset_name,
      };
    }

    dispatch(changeFilter(filterData));
  };

  const handleEnterItem = (item, value) => {
    setFilterValue((prev) => ({
      ...prev,
      [item.type]: [...prev[item.type], { value: value, label: value }],
    }));

    const filterData = {
      application: application,
      type: item.type,
      attribute: value,
    };

    dispatch(changeFilter(filterData));
  };

  const handleChangeKeyword = (e) => {
    const value = e.target.value;
    const name = e.target.name;

    if (!isNaN(value)) {
      setFilterValue((prev) => ({
        ...prev,
        [name]: { value: value },
      }));
    }
  };

  const handleBlur = (e, item) => {
    const value = e.target.value;
    const name = e.target.name;

    if (value === "") return;

    const num = parseFloat(value);
    let newValue = value;

    if (value && !isNaN(value)) {
      newValue = num.toFixed(2) + item.unit;

      setFilterValue((prev) => ({
        ...prev,
        [name]: { value: newValue },
      }));

      const filterData = {
        application: application,
        type: name,
        attribute: value,
      };

      dispatch(changeFilter(filterData));
    }
  };

  const handleFocus = (e) => {
    const value = e.target.value;
    const name = e.target.name;

    if (value === "") return;
    const newValue = parseFloat(value.replace(/%$/, ""));

    setFilterValue((prev) => ({
      ...prev,
      [name]: { value: newValue },
    }));
  };

  const renderDropdownContent = (item, i) => {
    if (item.filter_bar) {
      if (item.input_type === "dropdown") {
        if (item.multiple_selection) {
          return (
            <div className="max-w-[150px] w-[150px]">
              <FiterMultiDropdown
                key={i}
                item={item}
                filterValue={filterValue}
                onChange={handleChangeMultiDropdown}
                minWidth={"110px"}
                SpySearch={true}
              />
            </div>
          );
        } else {
          return (
            <FilterDropdown
              key={i}
              options={item.values.map((option) => ({
                label: option.value,
                value: option.request_value,
              }))}
              placeholder={item.name}
              onChange={(value) => handleChangeDropdown(item, value)}
              value={filterValue[item.type]}
              width={"120px"}
            />
          );
        }
      } else {
        if (item.multiple_selection) {
          return (
            <FiterMultiKeyword
              key={i}
              item={item}
              inputValue={inputValue}
              setInputValue={setInputValue}
              handleEnterItem={handleEnterItem}
              filterValue={filterValue}
              setFilterValue={setFilterValue}
            />
          );
        } else {
          return (
            <FilterKeyword
              key={i}
              item={item}
              onChange={handleChangeKeyword}
              handleBlur={(e) => handleBlur(e, item)}
              handleFocus={handleFocus}
              filterValue={filterValue}
              setFilterValue={setFilterValue}
            />
          );
        }
      }
    }
  };

  const { theme } = useContext(ThemeContext);

  return (
    <Layout>
      <section className="home-section bg-background overflow-auto xl:overflow-hidden">
        <div className="text border-b-[1px] border-borderBottom">
          <SubHeader date>
            <p className="title text-textTitle font-semibold tracking-wide text-[20px]">
              Spy
              <span className="bg-gradient-to-br from-primary to-main bg-clip-text text-transparent ml-1 font-semibold tracking-wide">
                Search
              </span>
            </p>
          </SubHeader>
        </div>

        <div className="flex flex-wrap gap-6 pb-4 pt-2 px-4 md:px-10 product-drp-header">
          {loading ? (
            Array.from({ length: 6 }).map((_, index) => (
              <Skeleton
                key={index}
                height={40}
                width={120}
                baseColor={theme === "dark" ? "#0e0e12" : "#f2f2f2"}
                highlightColor={theme === "dark" ? "#1a1a1a" : "#ffffff"}
              />
            ))
          ) : (
            <>
              {filterMenuDetails["filter"] &&
                filterMenuDetails["filter"].map((item, index) =>
                  renderDropdownContent(item, index)
                )}
              <div className="dropdown ">
                <button
                  className="dropbtn  dark:bg-gradient-to-br from-primary to-main    dark:text-text 
              cursor-pointer text-sm  font-medium flex pr-2 shadow  bg-custom-gradient-primary-light border-[1px] 
                  border-primary dark:border-[none] dark:border-0  text-primary"
                  onClick={handleSpySearch}>
                  Hinzufügen &nbsp;
                  <i className="bx bx-plus text-[18px]"></i>
                </button>
              </div>
            </>
          )}
        </div>

        {loading ? (
          <div className="px-4 md:px-10">
            <section className="gap-4 grid grid-rows-1 xl:grid-cols-2 lg:grid-cols-1 mb-20">
              <div>
                {/* Render multiple skeleton cards */}
                {Array.from({ length: 5 }).map((_, index) => (
                  <div key={index} className="relative rounded-2xl mb-4">
                    <div
                      className={`flex items-center rounded-2xl p-2 md:flex-row flex-col mx-[5px]   
                      card-left-product bg-white dark:bg-[#07070b] shadow-[0px_0px_4px_0px_#0000001A] dark:border-[0.5px]  
                      border-[#353539]`}>
                      <div className="right-div pr-5 px-3">
                        <Skeleton
                          height={130}
                          width={130}
                          baseColor={theme === "dark" ? "#0e0e12" : "#f9f9f9"}
                          highlightColor={
                            theme === "dark" ? "#1a1a1a" : "#ffffff"
                          }
                          className="md:h-[130px] h-[150px] w-[150px] object-contain bg-white rounded-2xl"
                        />
                      </div>
                      <div className="left-div flex-1 ">
                        <div className="flex justify-between items-center mb-2">
                          <Skeleton
                            height={30}
                            width={490}
                            baseColor={theme === "dark" ? "#0e0e12" : "#f9f9f9"}
                            highlightColor={
                              theme === "dark" ? "#1a1a1a" : "#ffffff"
                            }
                            className="mb-3"
                          />
                        </div>
                        <div className="flex gap-4 flex-wrap">
                          {/* Replace productContent with Skeletons */}
                          {Array.from({ length: 5 }).map((_, idx) => (
                            <div key={idx} className="text-left">
                              {/* <Skeleton
                                height={15}
                                width={80}
                                baseColor={
                                  theme === "dark" ? "#0e0e12" : "#f9f9f9"
                                }
                                highlightColor={
                                  theme === "dark" ? "#1a1a1a" : "#ffffff"
                                }
                                className="mb-1"
                              /> */}
                              <Skeleton
                                height={30}
                                width={80}
                                baseColor={
                                  theme === "dark" ? "#0e0e12" : "#f9f9f9"
                                }
                                highlightColor={
                                  theme === "dark" ? "#1a1a1a" : "#ffffff"
                                }
                                className="mb-1"
                              />
                            </div>
                          ))}
                        </div>
                        <div className="flex gap-4 mt-2 flex-wrap">
                          {Array.from({ length: 3 }).map((_, idx) => (
                            <div key={idx} className="text-left">
                              {/* <Skeleton
                                height={20}
                                width={80}
                                baseColor={
                                  theme === "dark" ? "#0e0e12" : "#f9f9f9"
                                }
                                highlightColor={
                                  theme === "dark" ? "#1a1a1a" : "#ffffff"
                                }
                                className="mb-1"
                              /> */}
                              <Skeleton
                                height={30}
                                width={80}
                                baseColor={
                                  theme === "dark" ? "#0e0e12" : "#f9f9f9"
                                }
                                highlightColor={
                                  theme === "dark" ? "#1a1a1a" : "#ffffff"
                                }
                              />
                            </div>
                          ))}
                          {/* Skeletons for buttons */}
                          <div className="text-left">
                            {/* <Skeleton
                              height={20}
                              width={90}
                              baseColor={
                                theme === "dark" ? "#0e0e12" : "#f9f9f9"
                              }
                              highlightColor={
                                theme === "dark" ? "#1a1a1a" : "#ffffff"
                              }
                              className="mb-1"
                            /> */}
                            <Skeleton
                              height={30}
                              width={80}
                              baseColor={
                                theme === "dark" ? "#0e0e12" : "#f9f9f9"
                              }
                              highlightColor={
                                theme === "dark" ? "#1a1a1a" : "#ffffff"
                              }
                            />
                          </div>
                          <div className="text-left">
                            {/* <Skeleton
                              height={20}
                              width={90}
                              baseColor={
                                theme === "dark" ? "#0e0e12" : "#f9f9f9"
                              }
                              highlightColor={
                                theme === "dark" ? "#1a1a1a" : "#ffffff"
                              }
                              className="mb-1"
                            /> */}
                            <Skeleton
                              height={30}
                              width={100}
                              baseColor={
                                theme === "dark" ? "#0e0e12" : "#f9f9f9"
                              }
                              highlightColor={
                                theme === "dark" ? "#1a1a1a" : "#ffffff"
                              }
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                ))}
              </div>
              <ProductRightCard selectedProduct={selectedProduct} />
            </section>
          </div>
        ) : productData.length > 0 ? (
          <div className="px-4 md:px-10">
            <section className="gap-4 grid grid-rows-1 xl:grid-cols-2 lg:grid-cols-1 mb-20">
              <SpyProductCard
                productData={productData}
                setSelectedProduct={setSelectedProduct}
                setOffSet={setOffSet}
                isLoader={isLoader}
                newProductData={newProductData}
                selectedProduct={selectedProduct}
              />
              <ProductRightCard selectedProduct={selectedProduct} />
            </section>
          </div>
        ) : (
          <NoSellersEmpty />
        )}
      </section>

      <CommonModal
        isOpen={isOpen}
        setIsOpen={setIsOpen}
        closeModal={closeModal}>
        <SellerTracking closeModal={closeModal} />
      </CommonModal>
    </Layout>
  );
};

export default SpySearch;
