export const sidebarData = [
  {
    title: "Dashboard",
    darkIcon: "static/DarkMode/overview.svg",
    lightIcon: "static/LightMode/overview.svg",
    enableDarkIcon: "static/DarkMode/dashboard-icon-active1.svg",
    enableLightIcon: "static/LightMode/dashboard-icon-active2.svg",
    link: "/home",
    width: 20,
  },
  {
    title: "Spy Search",
    darkIcon: "static/DarkMode/spysearch.svg",
    lightIcon: "static/LightMode/spysearch.svg",
    enableDarkIcon: "static/DarkMode/spy-icon-active1.svg",
    enableLightIcon: "static/LightMode/spy-icon-active2.svg",
    link: "/spySearch",
    width: 20,
  },
  {
    title: "A2A Flips",
    darkIcon: "static/DarkMode/a2aflip.svg",
    lightIcon: "static/LightMode/a2aflip.svg",
    enableDarkIcon: "static/DarkMode/amazon-active1.svg",
    enableLightIcon: "static/LightMode/amazon-active2.svg",
    link: "/a2aFlips",
    width: 20,
  },
  {
    title: "Preiswecker",
    darkIcon: "static/DarkMode/perisweacker.svg",
    lightIcon: "static/LightMode/perisweacker.svg",
    enableDarkIcon: "static/DarkMode/preiswecker-active1.svg",
    enableLightIcon: "static/LightMode/preiswecker-active2.svg",
    link: "/preiswecker",
    width: 20,
  },
  {
    title: "Dealhub",
    darkIcon: "static/DarkMode/dealhub.svg",
    lightIcon: "static/LightMode/dealhub.svg",
    enableDarkIcon: "static/DarkMode/dealhub-active1.svg",
    enableLightIcon: "static/LightMode/dealhub-active2.svg",
    link: "/dealHub",
    width: 20,
  },
  {
    title: "Store Search",
    darkIcon: "static/DarkMode/storesearch.svg",
    lightIcon: "static/LightMode/storesearch.svg",
    enableDarkIcon: "static/DarkMode/storesearch-icon-activedark.svg",
    enableLightIcon: "static/LightMode/storesearch-icon-activelight.svg",
    link: "/storeSearch",
    width: 20,
  },
  {
    title: "Guide",
    darkIcon: "static/DarkMode/guide.svg",
    lightIcon: "static/LightMode/guide.svg",
    enableDarkIcon: "static/DarkMode/academy-active1.svg",
    enableLightIcon: "static/LightMode/academy-active2.svg",
    url: "https://www.loom.com/share/folder/7cd44d80ead1474d8b6a88f76e2189f7",
    width: 20,
  },
];
