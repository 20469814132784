import React, { useContext } from "react";
import { useDropzone } from "react-dropzone";
import { ThemeContext } from "../context/themeContext";

const FileUpload = ({
  setUploadedFiles,
  uploadedFiles,
  setErrorMessage,
  errorMessage,
}) => {
  const { theme } = useContext(ThemeContext);

  const customStyles = {
    boxShadow: "0px 0px 5px 0px #0000001A",
  };

  const { getRootProps, getInputProps } = useDropzone({
    onDrop: (acceptedFiles) => {
      const validFiles = [];
      const errors = [];

      acceptedFiles.forEach((file) => {
        if (file.type === "text/csv") {
          validFiles.push(file);
          if (file.size > 10 * 1024 * 1024) {
            errors.push(
              `${file.name}: Datei ist zu groß. Maximal erlaubte Größe ist 10 MB.`
            );
          }
        } else {
          errors.push(
            `${file.name}: Ungültiges Dateiformat. Nur CSV-Dateien sind erlaubt.`
          );
        }
      });

      if (errors.length > 0) {
        setErrorMessage(errors.join(" "));
      } else {
        setErrorMessage("");
        setUploadedFiles(validFiles);
      }
    },
  });

  return (
    <div
      {...getRootProps()}
      className="lg:w-[74%] md:w-[85%] w-[77%] cursor-pointer"
    >
      <input {...getInputProps()} />
      <div
        className="shadow-md drop-shadow-md min-h-10 dark:bg-asian-filter-dark border-[1px] border-[#f2f2f2] 
        dark:border-none text-center rounded mt-4 flex justify-center items-center"
        style={customStyles}
      >
        <div className="text-center pt-20 pb-20">
          <img
            src={
              theme === "dark"
                ? "static/upload-icon2.svg"
                : "static/upload-icon1.svg"
            }
            width={30}
            className="m-auto"
            alt="uploadicon"
          />
          <div className="mt-4 flex text-sm text-uploadText justify-center items-center mb-2">
            <label
              htmlFor="file-upload"
              className="relative focus-within:outline-none text-center"
            >
              <span>Datei ablegen oder klicken</span>
            </label>
          </div>
          <p className="text-xs text-uploadText text-center max-w-[220px]">
            Erlaubte Formate *csv mehrere Dateien bis zu 10 MB
          </p>
          {errorMessage && (
            <p className="text-xs text-red-500 text-center max-w-[220px] mt-3">
              {errorMessage}
            </p>
          )}
        </div>
      </div>
      <ul>
        {uploadedFiles.map((file) => (
          <li className="text-textTitle mt-2" key={file.name}>
            {file.name}
          </li>
        ))}
      </ul>
    </div>
  );
};

export default FileUpload;
