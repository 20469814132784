import React, { useState } from "react";

const Tab = ({ filterMenuDetails, renderDropdownContent, presetNames }) => {
  const [activeTab, setActiveTab] = useState(presetNames[0]);

  return (
    <div className="w-full  mx-auto">
      <div className="flex items-center justify-center gap-5">
        {presetNames.map((tab, index) => (
          <button
            key={`${tab}-${index}`}
            className={`py-2 px-4 text-sm font-medium focus:outline-none ${
              activeTab === tab
                ? " bg-bg-tab-light dark:bg-bg-tab-dark text-[#488BFF] dark:text-[#15E8A8] rounded "
                : "text-[#5F5E5EE5] hover:text-[#488BFF] dark:hover:text-[#15E8A8] "
            }`}
            onClick={() => setActiveTab(tab)}
          >
            {tab}
          </button>
        ))}
      </div>
      <div
        className="flex justify-center  border-[1px] border-[#F2F2F2]  dark:border-[#2F2F34] 
         rounded my-5 h-[490px] overflow-auto"
      >
        <div className="p-4 ">
          <div className="grid w-fit gap-x-20 gap-y-6 md:grid-cols-2 xl:grid-cols-2 sm:grid-cols-1 py-4 ">
            {filterMenuDetails &&
              filterMenuDetails.filter.map((item, index) => {
                return (
                  <div key={index} className="text-left w-60">
                    <div className="flex items-center">
                      <div>
                        <p className="subtitle !text-[12px] mr-1">
                          {item.name}
                        </p>
                      </div>
                    </div>
                    {renderDropdownContent(item, activeTab)}
                  </div>
                );
              })}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Tab;
